import {httpClient} from "@/httpClient";

// Карточка артикула (парность - создание)
function createPair(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/pair`, payload).then(response => response.data)
}

// Карточка артикула (парность - обновление)
function updatePair(lrId: string, payload: object) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/pair/${lrId}`, payload).then(response => response.data)
}

// Карточка артикула (парность - удаление)
function deletePair(id: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/pair/${id}`).then(response => response.data)
}

export default {
  createPair,
  updatePair,
  deletePair,
}
