
import { defineComponent } from 'vue';
import marketplaces from '@/services/marketplaces';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: 'ImagesMarketplaceView',
  components: {
    Spinner,
  },
  props: {
    marketplaces: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    images: [] as any,
    pickedImages: [] as any,
    isLoading: false as boolean,
    isUploadingImage: false as boolean,
    selectedMarketplaceForImages: null as any
  }),
  computed: {
    checkImage(): any {
      return (image: any) => {
        const idx = this.pickedImages.findIndex((pickedImage: any) => pickedImage === image)

        return idx !== -1
          ? 'images-with-signed__active-image'
          : ''
      }
    }
  },
  methods: {
    async deleteImages() {
      this.isLoading = true
      await Promise.all(this.pickedImages.map(async (pickImage: any) => {
        const arr = pickImage.split('/')
        await marketplaces.deleteImageMarketplace(this.$route.params.id, arr[arr.length - 1])
        this.images = this.images.filter((image: any) => image !== pickImage)
      }));
      this.isLoading = false
      this.pickedImages = []
    },
    async uploadFiles(e: any) {
      const files = e.target.files || e.dataTransfer.files;
      this.isUploadingImage = true
      const firstMarketplace = this.marketplaces && this.marketplaces[0] as any
      const marketplaceId = firstMarketplace && firstMarketplace.id

      if (marketplaceId) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('articleId', String(this.$route.params.id));
          formData.append('extension', files[i].type.split('/').pop());
          formData.append('file', files[i]);
          formData.append('marketplaceId', marketplaceId);
        }

        const newImage = await marketplaces.addImageForMarketplace(formData)

        this.images = [
          ...this.images,
          newImage
        ]
        this.isUploadingImage = false
      }
    },
    pickFiles() {
      (document.getElementById(`input-file`) as HTMLInputElement).click()
    },
    pickImage(image: any) {
      const isImageSelected = this.pickedImages.findIndex((pickedImage: any) => pickedImage === image) !== -1

      if (isImageSelected) {
        this.pickedImages = this.pickedImages.filter((pickedImage: any) => pickedImage !== image)
        return;
      }
      this.pickedImages = [
        ...this.pickedImages,
        image
      ]
    },
  },
  async mounted() {
    this.isLoading = true
    if (this.$store.getters.getArticleCard?.article) {
      await Promise.all(
        this.marketplaces.map(async (marketplace: any) => {
          const image = await marketplaces.getImagesByMarketplaceId(marketplace.id, this.$store.getters.getArticleCard?.article)
          if (image && image.length) {
            this.images = [
              ...this.images,
              ...image
            ]
          }
        })
      )

      this.isLoading = false
    }
  }
});
