
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import marketplaces from '@/services/marketplaces';
import ImagesMarketplaceView from '@/components/article-card/other-images-views/ImagesMarketplaceView.vue';
import AttributesImages from '@/components/article-card/attributes/AttributesImages.vue';

export default defineComponent({
  name: 'AddImagesForOzonModal',
  emits: ['saveImages'],
  components: { AttributesImages, ModalWrapper, BaseTabs, ImagesMarketplaceView },
  data: () => ({
    images: [] as any,
    pickedImages: [] as any,
    isLoading: true as boolean,
    marketplaces: [] as any,
    tabs: [
      { name: 'Со знаками (Ozon, WB, Aliexpress)', query: 'signed' },
      { name: 'Без знаков вертикаль (Sber, Yandex)', query: 'no-signed' },
      { name: 'Горизонталь без знаков', query: 'horizontal-no-signed' },
      { name: 'Фото изделия', query: 'product' }
    ]
  }),
  async mounted() {
    this.marketplaces = await marketplaces.all()
  }
})
