import {httpClient} from '@/httpClient';

async function addImage(payload: any) {
  const response = await httpClient.post(`/article/${localStorage.getItem('cid')}/ozon-image`, payload);
  return response.data;
}

async function getImageForArticle(articleName: any) {
  const response = await httpClient.get(`/article/${localStorage.getItem('cid')}/ozon-image/${articleName}`)
  return response.data.map((image: any) => `http://80.94.230.41:8080/files/${image}`)
}

async function deleteImageOzon(articleId: any, imageName: any) {
  await httpClient.delete(`/article/${localStorage.getItem('cid')}/ozon-image?articleId=${articleId}&imageName=${imageName}`)
}

export default {
  addImage,
  getImageForArticle,
  deleteImageOzon
}
