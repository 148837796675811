import {httpClient} from "@/httpClient";

// Карточка артикула (аналог - создание)
function createAnalog(payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/analog`, payload).then(response => response.data)
}

// Карточка артикула (аналог - обновление)
function updateAnalog(payload: object) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/analog`, payload).then(response => response.data)
}

// Карточка артикула (аналог - удаление)
function deleteAnalog(id: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/analog/${id}`).then(response => response.data)
}

export default {
  createAnalog,
  updateAnalog,
  deleteAnalog,
}
