
  import {defineComponent, ref} from 'vue';
  import vehicles from "@/services/vehicles";
  import UnconfirmedWrapper from "./../unconfirmed-blocks/UnconfirmedWrapper.vue"
  import articles from "@/services/articles";
  import VehiclesTableCompact from '/src/components/vehicles/VehiclesTableCompact.vue'
  import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
  import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
  import crossApplicab from "@/services/crossApplicab";


  export default defineComponent({
    name: 'Vehicles',
    emits: ['close-modal'],
    components: {
      UnconfirmedWrapper,
      VehiclesTableCompact
    },
    props: {
      confirmed: {
        type: Object,
        default: () => null
      }
    },

    setup(props, {emit}) {
      const vehicleOptions = ref({} as any);

      function getAllVehicles() {
        // vehicles.getAllVehicles().then((res: any) => {
        //   vehicleOptions.value = res.content;
        //   console.log(res.content)
        // })
      }

      function addCarToArticle(id: any) {
        console.log('id=', id)
        crossApplicab.approveVehicles('300001', [id]).then(async _ => {

        }).catch(error => {

        })
      }

      return {
        vehicleOptions,
        // getAllVehicles,
        addCarToArticle
      }
    },
  })
