
import {defineComponent} from "@vue/runtime-core";
import ozonImages from "@/services/ozonImages";
import SelectImageModal from "@/components/modals/SelectImageModal.vue";

export default defineComponent({
  name: 'ImageSelect',
  emits: ['addSavedImages', 'addSavedImagesForCategory', 'clearSavedImagesCategory'],
  components: { SelectImageModal },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    savedImages: [] as any,
    isOpenImageModal: false as boolean,
  }),
  methods: {
    openModalWithImages() {
      this.isOpenImageModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-images`)!
        element.style.display = 'flex'
      }, 0)
    },
    saveImagesForOzon(pickedImages: any) {
      this.savedImages = pickedImages
      this.$emit('addSavedImages', this.savedImages)
    },
    goToViewPhoto(link: any) {
      window.open(link, '_blank');
    },
    deleteImage(pickedImage: any) {
      this.savedImages = this.savedImages.filter((image: any) => image !== pickedImage)
      this.$emit('addSavedImages', this.savedImages)

      const checkImage = this.images && this.images.length
          ? pickedImage
          : `http://80.94.230.41:8080/files/${pickedImage}`

      this.$emit('clearSavedImagesCategory', checkImage)
    }
  },
  watch: {
    images() {
      if (this.images && this.images.length) {
        this.savedImages = this.images
      }
    }
  }
})
