
import {defineComponent, ref, computed} from 'vue';
import {useStore} from '@/store'
import TreeItem from './TreeItem.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {roles} from "@/services/roles/serviceRoles";

export default defineComponent({
  name: 'TreeItem',
  emits: ['add-item', 'remove-item', 'make-folder'],
  components: {
    BaseIcon
  },
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const isOpen = ref(false as boolean);
    const article = computed(() => store.getters.getArticleCard);
    const checkUserRoleForDeleteTree = computed(() => {
      return store.getters.getAllPermissions.edit_article[store.getters.getUserRole]
    })
    const isFolder = computed(() => {
      let item: any = props.item;
      return item && item.children && item.children.length
    });

    const iconArrowType = computed(() => {
      return !isFolder.value
        ? 'chevron-right'
        : isOpen.value
          ? 'chevron-up'
          : 'chevron-down'
    })

    function toggleItem() {
      if (isFolder.value) isOpen.value = !isOpen.value
    }

    function makeFolder() {
      if (!isFolder.value && checkUserRoleForDeleteTree.value) {
        emit('add-item', props.item);
        isOpen.value = true
      }
    }

    function removeItem() {
      emit('remove-item', props.item)
    }

    return {iconArrowType, checkUserRoleForDeleteTree, isOpen, article, isFolder, toggleItem, makeFolder, removeItem}
  }
})
