
import { defineComponent, ref, computed } from 'vue';
import { useStore } from '@/store';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import router from '@/router';
import { roles } from '@/services/roles/serviceRoles';
import routerService from "@/services/routerService";
import {useRoute, useRouter} from "vue-router";
import deviceMixin from '@/mixins/deviceMixin';

export default defineComponent({
  name: 'SideBar',
  mixins: [deviceMixin],
  components: {
    BaseIcon,
    SmallSpinner,
  },
  props: {
    isOpenSideBarMobile: Boolean,
    isInfoLoading: {
      type: Boolean,
      default: () => false,
    },
    originalArticle: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute()
    const router = useRouter()
    const getCurrentTabByRoute = computed(() => route.query?.tab);
    const notValue = ref(['BASIC', 'OZON', 'HISTORY', 'DOCUMENTS', 'LOGISTIC', 'ATTRIBUTES', 'CERTIFICATE']);
    const article: { [x: string]: any } = computed(() => store.getters.getArticleCard);
    const sideBar: { [x: string]: any } = computed(() => store.getters.getSideBarCard);
    const params: { [x: string]: any } = computed(() => store.getters.getParamsCard);
    const activeTab: { [x: string]: any } = computed(() => store.getters.getActiveTabCard);
    const userRole = computed(() => store.getters.getUserRole);

    const canUserActionHistory = computed(() => store.getters.getAllPermissions.audit[store.getters.getUserRole]);
    const canUserAddDuplicateCross = computed(() => store.getters.getAllPermissions.add_duplicate_cross[store.getters.getUserRole]);
    const isShowCertificateTab = computed(() => store.getters.getAllPermissions.certificates[store.getters.getUserRole]);

    const changedSideBar = ref([...sideBar.value]);

    function setActiveTab(item: any) {
      if (route.query.tab !== item.key) {
        routerService.setQuery('tab', item.key, route.query, router)
      }
    }

    if (!canUserActionHistory.value) {
      changedSideBar.value = changedSideBar.value.filter((el: any) => el.key !== 'HISTORY');
    }

    if (!canUserAddDuplicateCross.value) {
      changedSideBar.value = changedSideBar.value.filter((el: any) => el.key !== 'OZON');
    }
    if (!isShowCertificateTab.value) {
      changedSideBar.value = changedSideBar.value.filter((el: any) => el.key !== 'CERTIFICATE');
    }

    function getCountTab(tab: any) {
      const found = params.value.find((o: any) => o.key === tab.key);

      let one = 0;
      let two = 0;
      let three = found.unconfirmed.length;

      switch (tab.key) {
        case 'CROSS':
          three = found.confirmed.filter((o: any) => o.direction === 'ANALOG').length
          one = found.confirmed.filter((o: any) => o.direction === 'CM').length;
          two = found.confirmed.filter((o: any) => o.direction === 'OE').length;
          return `${one} / ${two} / ${three}`;
        case 'PARAMS':
          one = found.confirmed.filter((o: any) => o.paramVal).length;
          return `${one}`;
        case 'APPLICABILITY':
          one = found.confirmed.length;

          const validConfirmedVehicles = []
          const dataConfirmedVehicles = found.confirmed.map((confirmedVehicle: any) => {
              return [
                confirmedVehicle.brand,
                confirmedVehicle.model,
                confirmedVehicle.volume,
                confirmedVehicle.fuelType,
                confirmedVehicle.kw && confirmedVehicle.hp
                  ? `${confirmedVehicle.kw} kW / ${confirmedVehicle.hp} PS`
                  : null,
                confirmedVehicle.vehYears,
                confirmedVehicle.bodyType,
                confirmedVehicle.modBodyNum
              ]
            });

          dataConfirmedVehicles.map((convertedDataConfirmedVehicle: Array<string | number | null>) => {
            const isValidVehicleValue = convertedDataConfirmedVehicle.some((vehicleValue: string | number | null) => {
              return !!vehicleValue
            })


            if (isValidVehicleValue) {
              validConfirmedVehicles.push(convertedDataConfirmedVehicle)
            }
          })

          return `${validConfirmedVehicles.length}`;
        default:
          return found.confirmed.length ? found.confirmed.length : 'Нет';
      }
    }

    function goToBack() {
      router.go(-1);
    }

    return {
      notValue, article, sideBar, activeTab, setActiveTab, getCountTab, goToBack, changedSideBar, getCurrentTabByRoute
    };
  },
});
