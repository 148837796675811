
import {defineComponent, ref, watch, computed} from 'vue';
import {useStore} from '@/store'
import articles from "@/services/articles";
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchEntities from '@/components/search/SearchEntities.vue';
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import params from "@/services/params";

export default defineComponent({
  name: 'Parameters',
  emits: ['close-modal'],
  components: {
    ModalWrapper,
    SearchEntities
  },
  props: {
    content: {
      type: Object,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const parameter = ref({} as any);
    const parameterValue = ref('' as string);
    const searchText = ref('' as string);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const article = computed(() => store.getters.getArticleCard);
    const view = computed(() => {
      let params = computed(() => store.getters.getParamsCard);
      return params.value.find((o: any) => o.key === 'PARAMS').view
    });
    const getValue = computed(() => {
      let result = `${props.content.formattedValue}`;
      if (props.content.criteriaDescription) {
        let regex = /\[(.*?)\]/;
        let matched = regex.exec(props.content.criteriaDescription);
        if (matched) {
          result = result + ' ' + `${matched[1]}`
        }
      }
      return result
    });

    watch(() => props.content, (val) => {
      if (val && view.value === 'CONFIRMED') {
        parameter.value = val;
        parameter.value.name = val.param.name;
        parameterValue.value = val.paramVal
      } else if (val && view.value === 'UNCONFIRMED') {
        parameterValue.value = val.formattedValue
      } else {
        setDefaultData()
      }
    });

    function setDefaultData() {
      parameter.value = {};
      parameterValue.value = ''
    }

    function openSearchModal() {
      searchText.value = parameter.value.name;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function updateEntity(entity: any) {
      parameter.value = entity;
      parameter.value.name = entity.name;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function editParameter() {
      let payload = {id: parameter.value.id, paramVal: parameterValue.value};
      params.updateParameter(payload).then(response => {
        store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, {key: 'PARAMS', value: response});
        setDefaultData();
        emit('close-modal')
      })
    }

    function createParameter() {
      let payload = {
        articleId: article.value.articleId,
        lang: parameter.value.lang,
        productGroup: article.value.productGroup.id,
        paramId: parameter.value.id,
        directAdd: true,
        paramVal: parameterValue.value
      };
      params.createParameter(payload).then(response => {
        response.param = parameter.value;
        store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {key: 'PARAMS', value: response});
        setDefaultData();
        emit('close-modal')
      })
    }

    return {
      searchText,
      getRandomId,
      view,
      article,
      parameter,
      parameterValue,
      getValue,
      openSearchModal,
      updateEntity,
      editParameter,
      createParameter
    }
  }
})
