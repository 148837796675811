
import { defineComponent, ref, computed, onMounted } from 'vue';
import { store, useStore } from '@/store';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import Pairing from "./modal-blocks/Pairing.vue";
import UnconfirmedWrapper from "./unconfirmed-blocks/UnconfirmedWrapper.vue"
import ConfirmedWrapper from "./confirmed-blocks/ConfirmedWrapper.vue"
import Analog from "./modal-blocks/Analog.vue"
import AddTreeElement from './modal-blocks/AddTreeElement.vue'
import Parameters from "./modal-blocks/Parameters.vue";
import Vehicles from "./modal-blocks/Vehicles.vue";
import AddCross from "./modal-blocks/AddCross.vue"
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import AutoDirectoryWrapper from '@/components/article-card/AutoDirectoryWrapper.vue';
import {roles} from "@/services/roles/serviceRoles";
import articles from '@/services/articles';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import ContentWithFiltersWrapper
  from '@/components/article-card/applicability-filters/ContentWithFiltersWrapper.vue';
import Spinner from '@/components/Spinner.vue';
import cross from '@/services/cross';
import params from '@/services/params';
import crossApplicab from '@/services/crossApplicab';
import BaseBadge from '@/components/ui/BaseBadge.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import DropdownOptions from '@/components/dropdown/DropdownOptions.vue';
import BaseDropdown from '../dropdown/BaseDropdown.vue';

export default defineComponent({
  name: 'ParamsWrapper',
  emits: ['isInfoLoading'],
  components: {
    BaseDropdown,
    DropdownOptions,
    DropdownEllipsis,
    BaseBadge,
    Spinner,
    ContentWithFiltersWrapper,
    AutoDirectoryWrapper,
    BaseIcon,
    ModalWrapper,
    Pairing,
    UnconfirmedWrapper,
    ConfirmedWrapper,
    Analog,
    AddTreeElement,
    Parameters,
    Vehicles,
    AddCross
  },
  props: {
    content: {
      type: Object,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const entityModal = ref(null as any);
    const activeMenu = ref('CONFIRMED' as string);
    const editTextNoteMode = ref(false)
    const checkedCrosses = ref([]) as any
    const checkedColumnCrosses = ref([]) as any

    const views = computed(() => {
      const resultViews = []

      if (checkUserRoleForEditCardArticle.value) {
        resultViews.push({
          key: 'UNCONFIRMED',
          name: 'Неподтверждённые',
          count: String(props.content.unconfirmed.length)
        });

        if (props.content.key === 'APPLICABILITY') {
          resultViews.push({
            key: 'DIRECTORY',
            name: 'Справочник авто',
          })
        }
      }

      return [
        {key: 'CONFIRMED', name: 'Подтверждённые'},
        ...resultViews
      ]
    });

    const getConfirmedIds = computed(() => {
        return props.content.confirmed && props.content.confirmed.length ?
          // props.content.confirmed.map((vehicle: any) => vehicle.tecdocVehicleId) : []
          props.content.confirmed.map((vehicle: any) => vehicle.vehicleId) : []
    })

    const article = computed(() => store.getters.getArticleCard);
    const textNote = ref(article.value.note)

    const checkUserRoleForEditCardArticle = computed(() => {
      return store.getters.getAllPermissions.edit_article[store.getters.getUserRole]
    })
    const params = computed(() => store.getters.getParamsCard);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const showViews = computed(() => {
      return checkUserRoleForEditCardArticle.value && ['CROSS', 'PARAMS', 'APPLICABILITY'].includes(props.content.key)
    });
    const showUnconfirmedParams = computed(() => {
      return showViews.value && !!props.content.unconfirmed.length && props.content.view === 'UNCONFIRMED'
    });
    const showConfirmedParams = computed(() => {
      return props.content.view === 'CONFIRMED'
    });

    const showAutoDirectoryParams = computed(() => {
      return props.content.view === 'DIRECTORY'
    });

    const getModalName = computed(() => {
      let found = params.value.find((o: any) => o.key === props.content.key);
      return found ? found.name : null
    });

    const getButtons = computed(() => {
      let result = [];
      switch (props.content.key) {
        case 'CROSS':
          result.push({type: 'add-red', title: 'Добавить кросс', method: openModal});
          break;
        case 'ANALOG':
          result.push({type: 'add', title: 'Добавить аналог', method: createItem});
          break;
        case 'PAIR':
          result.push({type: 'add', title: 'Добавить парный артикул', method: createItem});
          break;
        case 'TREE':
          result.push({type: 'add-red', title: 'Добавить дерево', method: openModal});
          break;
        case 'PARAMS':
          result.push({type: 'add-red', title: 'Добавить параметр', method: createItem});
          break;
        case 'APPLICABILITY':
          // result.push({type: 'add-red', title: 'Добавить применяемость', method: createItem});
          break;
        default:
          break;
      }
      return result
    });

    function createItem() {
      entityModal.value = null;
      openModal()
    }

    function editItem(item: any) {
      entityModal.value = item;
      openModal()
    }

    function setInfoLoading(item: any) {
      emit('isInfoLoading', item);
    }

    function openModal() {
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeModal() {
      entityModal.value = null;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function changeView(item: any) {
      let payload = {key: props.content.key, value: item.key};
      store.commit(CardArticleMutationTypes.CHANGE_VIEW, payload);
    }

    async function updateArticleNote() {
      try {
        await articles.updateNote({
          note: textNote.value,
          articleId: article.value.articleId
        })

        store.commit(CardArticleMutationTypes.UPDATE_NOTE, textNote.value);
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')

      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }

      editTextNoteMode.value = false
    }

    function cancelTextNoteChanges() {
      textNote.value = article.value.note
      editTextNoteMode.value = false
    }

    function setCross(cross: any) {
      const index = checkedCrosses.value.findIndex((o: any) => o === cross.id)

      if (index !== -1) {
        checkedCrosses.value.splice(index, 1);
      } else {
        checkedCrosses.value.push(cross.id)
      }
    }

    async function deleteSomeCrosses() {
      await cross.deleteCrosses(checkedCrosses.value.join(','))

      checkedCrosses.value.map((crossId: any) => {
        store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {
          key: 'CROSS',
          value: crossId
        });
      })

      checkedCrosses.value = []
      store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      checkedColumnCrosses.value = []
    }

    async function setFocusCrosses() {
      await cross.setFocusCrosses(checkedCrosses.value).then(async (crosses: any[]) => {
        crosses.map((cross: any) => {
          store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, {key: 'CROSS', value: cross.id});
        })
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(error => {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      })
    }

    function uncheckedColumnCrosses(crosses: any, tabKey: any) {
      if (checkedColumnCrosses.value.includes(tabKey)) {
        checkedColumnCrosses.value = checkedColumnCrosses.value.filter((checkedCross: any) => checkedCross !== tabKey)
      }


      crosses.map((cross: any) => {
        const index = checkedCrosses.value.findIndex((o: any) => o === cross.id)

        if (index !== -1) {
          checkedCrosses.value.splice(index, 1);
        }
      })
    }

    function setColumnCrosses(crosses: any, tabKey: any) {
      checkedCrosses.value = []
      checkedColumnCrosses.value = []

      if (!checkedColumnCrosses.value.includes(tabKey)) {
        checkedColumnCrosses.value.push(tabKey)
      }

      crosses.map((cross: any) => {
        const index = checkedCrosses.value.findIndex((o: any) => o === cross.id)

        if (index === -1) {
          checkedCrosses.value.push(cross.id)
        }
      })
    }

    return {
      checkedColumnCrosses,
      uncheckedColumnCrosses,
      setColumnCrosses,
      deleteSomeCrosses,
      cancelTextNoteChanges,
      updateArticleNote,
      entityModal,
      activeMenu,
      views,
      article,
      getButtons,
      checkUserRoleForEditCardArticle,
      showUnconfirmedParams,
      showViews,
      showConfirmedParams,
      showAutoDirectoryParams,
      getModalName,
      getRandomId,
      getConfirmedIds,
      openModal,
      editItem,
      closeModal,
      changeView,
      setInfoLoading,
      textNote,
      editTextNoteMode,
      setCross,
      checkedCrosses,
      setFocusCrosses
    }
  }
})
