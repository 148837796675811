
import { defineComponent } from 'vue';
import timeService from '@/services/timeService';
import { Category, Status, Type} from "@/components/types/enums";
import audit from "@/services/audit";
import { LogisticParams } from '@/store/modules/article-card/action-types';

export default defineComponent({
  name: 'HistoryPage',
  components: {},
  emits: ['loading', 'setPageable', 'setOptionsDropdownFilters', 'updateContent'],
  props: {
    changeByType: {
      type: String,
      required: true,
      default: () => null
    },
    id: {
      type: String,
      required: true,
      default: () => null
    },
    page: {
      type: String,
      required: true,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => null
    },
    range: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    cross: [] as any,
    isDropdownRevealed: false as boolean,
    pickedDropdownOption: 'Все' as string,
    isShowHistory: true as boolean,
    dataChanges: [] as any,
    content: [] as any,
    resContent: [] as any,
    crossApplicab: [] as any,
    deleteCrossApplicab: [] as any,
    infoPaging: {
      totalPaged: null as any,
      currentPage: null as any
    }
  }),
  computed: {
    getInfoAction() {
      return (dataChanges: any) => {
        if (dataChanges.action === 'ADD_CROSS_APPLICAB') {
          return `<div>Подтверждено:
                    <span style="font-weight: 600">Применяемость</span>
                    <span>${this.crossApplicab.filter((el: any) => el.date.substring(0, 14) === dataChanges.date.substring(0, 14)).length} позиций</span>
                </div>`
        }

        if (dataChanges.action === 'DELETE_CROSS_APPLICAB') {
          return `<div>Удалено:
                    <span style="font-weight: 600">Применяемость</span>
                    <span>${this.deleteCrossApplicab.filter((el: any) => el.date.substring(0, 14) === dataChanges.date.substring(0, 14)).length} позиций</span>
                </div>`
        }

        if (dataChanges.action === 'UPDATE') {
          const result = dataChanges.desc.reduce((res: any, action: any) => {
            if (action.changedData === 'STATUS') {
              const statuses: any = Status
              const oldStatus = statuses[action.oldData]
              const newStatus = statuses[action.newData]
              res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Статус артикула</span>
                    <span class="history-action__changes-info">${oldStatus}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newStatus}</span>
                </div>`)
            }

            if (action.changedData === 'COMMENTARTICLE') {
              if (action.action === 'created') {
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Примечание к описанию</span>
                    <span class="history-action__changes-info">${action.newData}</span>
                </div>`)
              }

              if(action.action === 'updated') {
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Примечание к описанию артикула</span>
                    <span class="history-action__changes-info">${action.oldData}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${action.newData}</span>
                </div>`)
              }
            }

            if (action.changedData === 'COMMENTAPPLIC') {
              if (action.action === 'created') {
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Применяемость артикула</span>
                    <span class="history-action__changes-info">${action.newData}</span>
                </div>`)
              }

              if(action.action === 'updated') {
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Применяемость артикула</span>
                    <span class="history-action__changes-info">${action.oldData}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${action.newData}</span>
                </div>`)
              }
            }

            if (action.changedData === 'CATEGORY') {
              if (action.action === 'updated') {
                const categories: any = Category
                const oldCategory = categories[action.oldData]
                const newCategory = categories[action.newData]
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Категория</span>
                    <span class="history-action__changes-info">${oldCategory}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newCategory}</span>
                </div>`)
              }
            }

            if (action.changedData === 'PRODUCT_GROUP') {
              if (action.action === 'updated') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Товарная группа</span>
                    <span class="history-action__changes-info">${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }
            }

            if (action.changedData === "PRODUCT_SUBGROUP") {
              if (action.action === 'updated') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Подгруппа</span>
                    <span class="history-action__changes-info">${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }

              if (action.action === 'created') {
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Подгруппа</span>
                    <span class="history-action__changes-info">${action.newData}</span>
                </div>`)
              }
            }

            if (action.changedData === "PRODUCTSUBSUBGROUPID") {
              if (action.action === 'updated') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Подгруппа 2</span>
                    <span class="history-action__changes-info">ID ${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">ID ${newValue}</span>
                </div>`)
              }

              if (action.action === 'created') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Подгруппа 2</span>
                    <span class="history-action__changes-info">ID ${newValue}</span>
                </div>`)
              }

              if (action.action === 'deleted') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Удалено:
                    <span style="font-weight: 600">Подгруппа 2</span>
                    <span class="history-action__changes-info">ID ${oldValue}</span>
                </div>`)
              }
            }

            if (action.changedData === "NAME") {
              if (action.action === 'updated') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Наименование</span>
                    <span class="history-action__changes-info">${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }

              if (action.action === 'created') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Наименование</span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }

              if (action.action === 'deleted') {
                const oldValue = action.oldData
                const newValue = action.newData
                res.push(`<div class="history-action__action">Удалено:
                    <span style="font-weight: 600">Наименование</span>
                    <span class="history-action__changes-info">${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }
            }

            if (action.changedData === 'COMMENTPRICE') {
              if (action.action === 'created') {
                const newValue = action.newData
                res.push(`<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Примечание к применяемости</span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }

              if (action.action === 'updated') {
                const newValue = action.newData
                const oldValue = action.oldData
                res.push(`<div class="history-action__action">Изменено:
                    <span style="font-weight: 600">Примечание к применяемости</span>
                    <span class="history-action__changes-info">${oldValue}</span>
                    <span class="history-action__arrow-right">></span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`)
              }
            }

            if (action.changedData === 'SALETYPE') {
              if (action.action === 'updated') {
                const types: any = Type
                const oldType = types[action.oldData]
                const newType = types[action.newData]
                res.push(`<div class="history-action__action">Изменено:
                  <span style="font-weight: 600">Тип</span>
                  <span class="history-action__changes-info">${oldType}</span>
                  <span class="history-action__arrow-right">></span>
                  <span class="history-action__changes-info">${newType}</span>
              </div>`)
              }
            }

            if (action.changedData === 'ARTICLE') {
              if (action.action === 'updated') {
                  const oldArticleName = action.oldData
                  const newArticleName = action.newData
                  res.push(
                      `<div class="history-action__action">Изменено:
                          <span style="font-weight: 600">Артикул</span>
                          <span class="history-action__changes-info">${oldArticleName}</span>
                          <span class="history-action__arrow-right">></span>
                          <span class="history-action__changes-info">${newArticleName}</span>
                      </div>`
                  )
              }
            }

            if (action.changedData === 'CODE') {
              if (action.action === 'updated') {
                const oldData = action.oldData
                const newData = action.newData
                res.push(
                  `<div class="history-action__action">Изменено:
                      <span style="font-weight: 600">Код</span>
                      <span class="history-action__changes-info">${oldData}</span>
                      <span class="history-action__arrow-right">></span>
                      <span class="history-action__changes-info">${newData}</span>
                    </div>`
                )
              }

              if (action.action === 'created') {
                const newData = action.newData
                res.push(
                  `<div class="history-action__action">Добавлено:
                      <span style="font-weight: 600">Код</span>
                      <span class="history-action__changes-info">${newData}</span>
                    </div>`
                )
              }
            }

            if (action.changedData === 'NOTE') {
              if (action.action === 'created') {
                res.push(
                  `<div class="history-action__action">Добавлено:
                          <span style="font-weight: 600">Описание</span>
                          <span class="history-action__changes-info">${action.newData}</span>
                    </div>`
                )
              }

              if (action.action === 'updated') {
                const prev = action.oldData
                const next = action.newData
                res.push(
                  `<div class="history-action__action">Изменено:
                          <span style="font-weight: 600">Описание</span>
                          <span class="history-action__changes-info">${prev}</span>
                          <span class="history-action__arrow-right">></span>
                          <span class="history-action__changes-info">${next}</span>
                      </div>`
                )
              }
            }
            return res
          }, [])
          return result.join(' ')
        }

        if (dataChanges.action === 'CREATE') {
          if (dataChanges.desc[0].changedData === 'CROSS') {
            return `<div>Добавлено:
                    <span style="font-weight: 600">Кроссировка</span>
                    ${dataChanges.desc[0].changedData} ${dataChanges.desc[0].newData}
                </div>`
          }
        }

        if (dataChanges.action === 'ADD_IMAGE') {
          if (dataChanges.desc[0].action === 'added_image') {
            return `<div class="history-action__action">Добавлено:
                    <span style="font-weight: 600">Фото для артикула</span>
                </div>`
          }
        }

        if (dataChanges.action === 'DELETE_IMAGE') {
          if (dataChanges.desc[0].action === 'deleted_image') {
            return `<div class="history-action__action">Удалено:
                    <span style="font-weight: 600">Фото для артикула</span>
                </div>`
          }
        }

        if (dataChanges.action === 'DELETE_CROSS') {
          const deletedCrosseByCurrentDate = this.cross.filter((el: any) => el.date.substring(0, 14) === dataChanges.date.substring(0, 14))
            // <p>Наименования: ${deletedCrosseByCurrentDate.map((cross: any) => {
            //    return cross.delete[0].data
            //  })}</p>
          return `<div>
                    <span style="font-weight: 600">Удалено:</span>
                    <p>Кроссировка: ${deletedCrosseByCurrentDate.length} позиции</p>

                    <table>
                      <tr>
                        <th>Бренд:</th>
                        <th>Наименование:</th>
                      </tr>
                      <tr>
                      </tr>
                      ${deletedCrosseByCurrentDate.map((cross: any) => {
                        const deleteCross = cross.delete[0].data.trim()
                        let brandName
                        let articleName

                        const indexBetweenBrandAndArticleName = deleteCross.lastIndexOf(' ')

                        const isIndexSeparationName = deleteCross.indexOf(';')

                        if (isIndexSeparationName !== -1) {
                          const splicedCrossName = deleteCross.split(';')
                          brandName = splicedCrossName[0]
                          articleName = splicedCrossName[1]
                        } else {
                          brandName = deleteCross.substring(0, indexBetweenBrandAndArticleName)
                          articleName = deleteCross.substring(indexBetweenBrandAndArticleName, deleteCross.length - 1)
                        }


                        return `<tr>
                                  <td>${brandName}</td>
                                  <td>${articleName}</td>
                                </tr>`
                      }).join('')}
                    </table>
                </div>`
        }

        if (dataChanges.action === 'CREATE_CROSS') {
          return `<div>Добавлено:
                    <span style="font-weight: 600">Кроссировка</span>
                    ${dataChanges.desc[0].changedData} ${dataChanges.desc[0].newData}
                </div>`
        }

        if (dataChanges.action === 'ADD_PARAM') {
          const desc = dataChanges.desc && dataChanges.desc[0]
          let addedParamName = ''
          let valueAddedParam = desc.newData

          if (desc?.action === 'ADD_PARAM') {
            const changedData = desc?.changedData
            let parameters: { [x: string]: any } = LogisticParams
            addedParamName = parameters[changedData]
          }

          if (dataChanges.desc[0].action === 'DELETE_PARAM') {
            return `<div>Удалено:
                <span style="font-weight: 600">Параметр</span>
                <span class="history-action__changes-info">${dataChanges.desc[0].newData}</span>
              </div>`
          } else {
            return `<div>Добавлено:
                <span style="font-weight: 600">Параметр ВГХ</span>
                <span class="history-action__changes-info">${addedParamName}</span>
                <span class="history-action__arrow-right">></span>
                <span class="history-action__changes-info">${valueAddedParam}</span>
              </div>`
        }

        if (dataChanges.action === 'ADD_VIDEO') {
          const newData = dataChanges.desc[0].newData
          const splitNewData = newData.split('\\')

          return `<div>Добавлено:
                  <span style="font-weight: 600">Видео</span>
                  <span class="history-action__changes-info">${splitNewData[splitNewData.length - 1]}</span>
                </div>`
        }

        if (dataChanges.action === 'DELETE_VIDEO') {
          const oldData = dataChanges.desc[0].oldData
          const splitOldData = oldData.split('\\')

          return `<div>Удалено:
                  <span style="font-weight: 600">Видео</span>
                  <span class="history-action__changes-info">${splitOldData[splitOldData.length - 1]}</span>
                </div>`
          }
        }

        if (dataChanges.action === 'UPDATE_PARAM') {
          if (dataChanges.desc[0].changedData === 'PARAMETER') {
            const newValue = dataChanges.desc[1].newData
            const changedParameter = dataChanges.desc[0].newData
            return `<div>Изменено:
                    <span style="font-weight: 600">Значение параметра ${changedParameter}</span>
                    <span class="history-action__changes-info">${newValue}</span>
                </div>`
          } else {
            const desc = dataChanges.desc && dataChanges.desc[0]
            let addedParamName = ''
            let oldData = desc.oldData
            let newData = desc.newData

            if (desc?.action === 'UPDATE_PARAM') {
              const changedData = desc?.changedData
              let parameters: { [x: string]: any } = LogisticParams
              addedParamName = parameters[changedData]
            }

            return `<div>Добавлено:
                  <span style="font-weight: 600">Параметр ВГХ</span>
                  <span class="history-action__changes-info">${addedParamName}: ${oldData}</span>
                  <span class="history-action__arrow-right">></span>
                  <span class="history-action__changes-info">${newData}</span>
                </div>`
          }
        }

        if (dataChanges.action === 'DELETE_PARAM') {
          return `<div>Удалено:
                  <span style="font-weight: 600">Параметр</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].oldData || dataChanges.desc[0].newData}</span>
                </div>`
        }

        if (dataChanges.action === 'ADD_PAIR') {
          let sidePairing

          switch (dataChanges.desc[0].action) {
            case 'added_left_pair':
              sidePairing = 'слева'
              break;
            case 'added_right_pair':
              sidePairing = 'справа'
              break;
          }

          return `<div>Добавлено:
                  <span style="font-weight: 600">Парность (сторона установки ${sidePairing})</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].newData}</span>
                </div>`
        }

        if (dataChanges.action === 'DELETE_PAIR') {
          let sidePairing

          switch (dataChanges.desc[0].action) {
            case 'deleted_left_pair':
              sidePairing = 'слева'
              break;
            case 'deleted_right_pair':
              sidePairing = 'справа'
              break;
          }

          return `<div>Удалено:
                  <span style="font-weight: 600">Парность (сторона установки ${sidePairing})</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].oldData}</span>
                </div>`
        }

        if (dataChanges.action === 'UPDATE_PAIR') {
          let sidePairing

          switch (dataChanges.desc[0].action) {
            case 'updated_left_pair':
              sidePairing = 'слева'
              break;
            case 'updated_right_pair':
              sidePairing = 'справа'
              break;
          }

          return `<div>Обновлено:
                  <span style="font-weight: 600">Парность (сторона установки ${sidePairing})</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].oldData}</span>
                  <span class="history-action__arrow-right">></span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].newData}</span>
                </div>`
        }

        if (dataChanges.action === 'ADD_ANALOG') {
          return `<div>Добавлено:
                  <span style="font-weight: 600">Аналог</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].newData} (описание: ${dataChanges.desc[1].newData})</span>
                </div>`
        }

        if (dataChanges.action === 'ADD_TREE') {
          if (dataChanges.desc[3]) { //проверяем или артикул был добавлен в дерево артикулов
            return `<div>Добавлено:
                  <span style="font-weight: 600">Артикул
                  <span class="history-action__changes-info">
                    ${dataChanges.desc[0].newData}
                    (описание: ${dataChanges.desc[1].newData ? dataChanges.desc[1].newData : 'не добавлено'},
                     количество: ${dataChanges.desc[2].newData})</span>

                   в ветку артикула <span class="history-action__changes-info">${dataChanges.desc[3].newData}</span></span>
                </div>`
          }

          return `<div>Добавлено:
                  <span style="font-weight: 600">Артикул в "Входит в - Состоит из"</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].newData}
                    (описание: ${dataChanges.desc[1].newData ? dataChanges.desc[1].newData : 'не добавлено'},
                    количество: ${dataChanges.desc[2].newData})
                  </span>
                </div>`
        }

        if (dataChanges.action === 'DELETE_TREE') {
          return `<div>Удалено:
                  <span style="font-weight: 600">Артикул из "Входит в - Состоит из"</span>
                  <span class="history-action__changes-info">${dataChanges.desc[0].oldData}</span>
                </div>`
        }

        if (dataChanges.action === 'DELETED_DRAW') {
          return `<div>Удалено:
                  <span style="font-weight: 600">Чертеж</span>
                </div>`
        }

        if (dataChanges.action === 'ADD_DRAW') {
          return `<div>Добавлено:
                  <span style="font-weight: 600">Чертеж</span>
                </div>`
        }
      }
    },
    getInfoTime() {
      return (date: any) => {
        const {hour, minutes} = timeService.getInfoFromUTCTime(date)
        return `${hour}:${minutes}`
      }
    },
    getTimeChanges() {
      return (date: string) => {
        const result = this.content.reduce((res: Array<object>, action: any) => {
          if (date === timeService.convertDate(action.date)) {
            res.push(action)
          }

          return res
        }, [])

        if (!result.length) {
          return false
        }

        return result
      }
    },
  },
  methods: {
    async setChangesData(currentPage: any = 0) {
      this.crossApplicab = []
      this.deleteCrossApplicab = []
      this.content = null
      this.dataChanges = []

      this.$emit('loading', true)

      let response

      if (this.range) {
        const dateFrom = timeService.convertDate(this.range.start).split('/').reverse().join('-')
        const dateTo = timeService.convertDate(this.range.end).split('/').reverse().join('-')

        await audit.getAllByTypeAndIdAndDate(this.changeByType, this.id, this.page, dateFrom, dateTo).then(res => {
          this.resContent = res.content
          response = res
        })

      } else {
        if (!this.resContent.length) {
          response = await audit.getAllByType(this.changeByType, this.id, this.page)
          this.resContent = response.content
        }
      }

      const times: any = []
      const authors: any = []

      this.content = this.resContent.reduce((res: any, action: any) => {
        times.push(timeService.convertDate(action.date))
        authors.push(action.username)
        if (action && action.desc) {

          if (action.action === 'DELETE_CROSS_APPLICAB') {
            if (!res.some((el: any) => el.action === 'DELETE_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }
            this.deleteCrossApplicab.push(action)
          }

          if (action.action === 'ADD_CROSS_APPLICAB') {
            if (!res.some((el: any) => el.action === 'ADD_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }

            this.crossApplicab.push(action)
          }

          if (action.action === 'DELETE_CROSS') {
            if (!res.some((el: any) => el.action === 'DELETE_CROSS' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }

            this.cross.push(action)
          }


          if (
              action.action !== 'ADD_CROSS_APPLICAB' &&
              action.action !== 'DELETE_CROSS_APPLICAB' &&
              action.action !== 'DELETE_CROSS'
          ) {
            res.push(action)
          }
        }

        return res
      }, [])

      this.dataChanges = [...new Set(times)]
      const filterAuthors = [...new Set(authors)]

      this.$emit('setPageable', {
        ...response.pageable,
        totalPages: response.totalPages,
        currentPage: this.infoPaging.currentPage,
      })

      this.$emit('setOptionsDropdownFilters', filterAuthors)
      this.$emit('updateContent', this.content.length)
      this.$emit('loading', false)
    }
  },
  async mounted() {
    await this.setChangesData()
  },
  watch: {
    filters() {
      const { author, changes } = this.filters

      if (author) {
        this.content = this.resContent.reduce((res: Array<object>, action: any) => {
          if (action.action === 'DELETE_CROSS_APPLICAB') {
            if (!res.some((el: any) => el.action === 'DELETE_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }
          }

          if (action.action === 'ADD_CROSS_APPLICAB') {
            if (!res.some((el: any) => el.action === 'ADD_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }
          }

          if (action.action === 'DELETE_CROSS') {
            if (!res.some((el: any) => el.action === 'DELETE_CROSS' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
              res.push(action)
            }
          }

          if (
              action.action !== 'ADD_CROSS_APPLICAB' &&
              action.action !== 'DELETE_CROSS_APPLICAB' &&
              action.action !== 'DELETE_CROSS'
          ) {
            if (author !== 'Все' && action.username.trim().toLowerCase() === author.trim().toLowerCase()) {
              res.push(action)
            } else {
              res.push(action)
            }
          }
          return res
        }, [])
      }
      if (changes) {
        this.content = this.resContent.reduce((res: any, action: any) => {
          if (changes === 'Все') {
            if (action.action === 'DELETE_CROSS_APPLICAB') {
              if (!res.some((el: any) => el.action === 'DELETE_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }

            if (action.action === 'ADD_CROSS_APPLICAB') {
              if (!res.some((el: any) => el.action === 'ADD_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }

            if (action.action === 'DELETE_CROSS') {
              if (!res.some((el: any) => el.action === 'DELETE_CROSS' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }

            if (
                action.action !== 'ADD_CROSS_APPLICAB' &&
                action.action !== 'DELETE_CROSS_APPLICAB' &&
                action.action !== 'DELETE_CROSS'
            ) {
              res.push(action)
            }
          }

          if (changes === 'Кроссировка') {
            if (action.action === 'DELETE_CROSS' && action.desc) {
              if (!res.some((el: any) => el.action === 'DELETE_CROSS' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }


            if (action.action === 'CREATE_CROSS' && action.desc) {
              res.push(action)
            }
          }

          if (changes === 'Применяемость') {
            if (action.action === 'DELETE_CROSS_APPLICAB') {
              if (!res.some((el: any) => el.action === 'DELETE_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }

            if (action.action === 'ADD_CROSS_APPLICAB') {
              if (!res.some((el: any) => el.action === 'ADD_CROSS_APPLICAB' && el.date.substring(0, 14) === action.date.substring(0, 14))) {
                res.push(action)
              }
            }
          }

          if (changes === 'Артикул') {
            if (action.action === 'UPDATE' && action.desc) {
              res.push(action)
            }
          }

          if (changes === 'Входит в - Состоит из') {
            if (action.action === 'ADD_TREE' || action.action === 'DELETE_TREE') {
              res.push(action)
            }
          }

          if (changes === 'Параметры') {
            if (action.desc && action.action === 'UPDATE_PARAM' || action.action === 'DELETE_PARAM' || action.action === 'ADD_PARAM') {
              res.push(action)
            }
          }

          return res
        }, [])
      }
      console.log(this.content.length)
      this.$emit('updateContent', this.content.length)
    },
    async range() {
      await this.setChangesData()
    }
  }
})
