import {httpClient} from "@/httpClient";
import axios from 'axios';

async function getDocumentsByArticleId(articleId: any) {
  return await httpClient.get(`/article/${localStorage.getItem('cid')}/documents/${articleId}`).then((response: any) => response.data)
}

async function addDocument(articleId: any, file: FormData) {
  return await httpClient.post(`/article/${localStorage.getItem('cid')}/documents/${articleId}`, file, {
    headers: {'Content-Type': 'multipart/form-data'}
  })
}

async function deleteDocumentsByArticleIdAndId(articleId: any, documentId: any) {
  return await httpClient.delete(`/article/${localStorage.getItem('cid')}/documents/${articleId}/${documentId}`)
}

async function getDocumentByArticleId(articleId: any, documentId: any) {
  return await httpClient.get(`/article/${localStorage.getItem('cid')}/documents/${articleId}/${documentId}`)
}

function download(articleId: any, documentId: any) {
  return `${axios.defaults.baseURL}/article/${localStorage.getItem('cid')}/documents/${articleId}/${documentId}/download`
}

export default {
  getDocumentsByArticleId,
  addDocument,
  deleteDocumentsByArticleIdAndId,
  getDocumentByArticleId,
  download
}
