
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BarcodesList',
  props: {
    items: {
      type: Array,
      default: [],
    }
  },
  components: {
  },
  data: () => ({
  }),
});
