
import { defineComponent } from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import BaseTable from '@/components/ui/BaseTable.vue';
import video from '@/services/video';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import marketplaces from '@/services/marketplaces';
import AddVideoModal from '@/components/modals/AddVideoModal.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import axios from 'axios';

export default defineComponent({
  name: 'ArticleVideosTable',
  components: {
    AddVideoModal,
    BaseTable,
    BaseIcon,
    SelectElementWithContentModal
  },
  data: () => ({
    allVideos: null as any,
    formDataFile: null as any,
  }),
  methods: {
    toggleShowModalDeleteVideo(isShow: boolean, id: any) {
      const element = document.getElementById(`modal-select-element-with-content-${id}`)!;
      element.style.display = isShow
        ? 'flex'
        : 'none'
    },
    toggleShowModalAddVideo(isShow: boolean) {
      const element = document.getElementById(`modal-video`)!;
      element.style.display = isShow
        ? 'flex'
        : 'none'
    },
    deleteVideo(marketplace: any, { name, link, isFile }: any) {
      if (isFile) {
        video.deleteVideo(this.$route.params.id, name)
      } else {
        video.deleteVideoLink(this.$route.params.id, name)
      }

      const indexMarketplaceVideo = this.allVideos.findIndex((data: any) => {
        return data.marketplace.name === marketplace.name
      })

      this.allVideos[indexMarketplaceVideo].videos = this.allVideos[indexMarketplaceVideo].videos.filter((video: any) => {
        return video.name !== name
      })

      this.toggleShowModalDeleteVideo(false, marketplace.name + link)
    },
    async fetchVideos() {
      const allMarketplaces = await marketplaces.all()

      this.allVideos = await Promise.all(allMarketplaces.map(async (marketplace: any) => {
        const fetchedVideos = await video.getVideosByMarketplacesId(this.$store.getters.getArticleCard.articleId, marketplace.id)

        const videos = fetchedVideos.map((video: any) => {
          if (video.split('/')[0] === localStorage.getItem('cid')) {
            return {
              name: video.split('/').pop(),
              link: `http://80.94.230.41:8080/files/${video}`,
              isFile: true
            }
          } else {
            return {
              name: video,
              link: video,
              isFile: false
            }
          }
        })

        return {
          marketplace,
          videos
        }
      }))

      this.allVideos = this.allVideos.filter((video: any) => video.marketplace.name !== 'Horizontal')
    }
  },
  computed: {
    headers() {
      return [
        {
          title: 'Маркетплейс',
          content: (item: any) => item.marketplace
        },
        {
          title: 'Видео',
          content: (item: any) => ''
        }
      ]
    }
  },
  async mounted() {
    await this.fetchVideos()
  }
});
