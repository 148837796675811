
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseDropdown from '@/components/dropdown/BaseDropdown.vue';
import BaseInput from './BaseInput.vue';
import Trigger from '@/components/dropdown/Trigger.vue';
import CustomTrigger from '@/components/ui/CustomTrigger.vue';
import SearchDropdownOptions from '@/components/ui/SearchDropdownOptions.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default defineComponent({
  name: 'CustomSelectModal',
  components: {
    BaseIcon,
    SearchDropdownOptions,
    CustomTrigger,
    Trigger,
    BaseInput,
    BaseDropdown,
  },
  data: () => ({
    pickedOption: null as any
  }),
  props: {
    showRemoveIcon: {
      type: Boolean,
      default: () => true
    },
    showCreateBtn: {
      type: Boolean,
      default: () => true
    },
    defaultCustomValue: {
      type: String,
      default: () => null
    },
    showInput: {
      type: Boolean,
      default: () => true
    },
    title: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    item: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Object,
      default: () => []
    },
    selectedOption: {
      default: () => null
    }
  },
  computed: {
    getOptionName() {
      return (option: any) => {
        if (this.type && this.type === 'VEHICLE_BRAND') {
          return option && option.brand || option
        } else if (this.type && this.type === 'VEHICLE_FULL_MODEL') {
          return option && option.modelFull || option
        } else if (this.type && this.type === 'VEHICLE_MODEL') {
          return option && option.model || option
        } else {
          return option
        }
      }
    }
  },
  methods: {
    clearPickedSelect() {
      this.pickedOption = null
      this.$emit('pickedOption', 'CLEAR_SELECTED')
    },
    update(type: string, event: any) {
      this.$emit('update', type, event)
    },
    closeDropdown() {
      const refDropdown = this.$refs.customDropdown as InstanceType<typeof BaseDropdown>;
      refDropdown.close()
    },
    chooseOption(option: any) {
      this.pickedOption = option
      this.$emit('pickedOption', option)
      this.closeDropdown()
    },
    chooseCustomOption(option: any) {
      this.pickedOption = option
      this.$emit('pickedOption', option)
      this.closeDropdown()
    }
  }
})
