
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from '@/store';
import { VehicleMutationTypes } from '@/store/modules/vehicles/mutation-types';
import { Category, Status, VehicleParameters } from '@/components/types/enums';
import VehiclesRow from './VehiclesRow.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
// import BaseSelect from '@/components/utils/BaseSelect.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import Spinner from '@/components/Spinner.vue';
import vehiclesRepository from '@/services/vehicles';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import vehicleBrands from '@/services/vehicleBrands';
import crossApplicab from '@/services/crossApplicab';
import BaseError from '@/components/ui/BaseError.vue';

export default defineComponent({
    name: 'VehiclesTableCompact',
    components: {
      BaseError,
      VehiclesRow,
      BaseIcon,
      // BaseSelect,
      SearchDropdown,
      Spinner
    },
    props: {
      confirmed: {
        type: Object,
        default: () => null
      }
    },
    setup() {
      const store = useStore();
      const search: { [x: string]: any } = ref({PRODUCT_GROUP: null, STATUS: null, CATEGORY: null});
      const vehicles: { [x: string]: any } = computed(() => store.getters.getVehicles);
      // const vehiclesBrands: { [x: string]: any } = computed(() => store.getters.getVehiclesBrands);
      const pageable: { [x: string]: any } = computed(() => store.getters.getVehiclePageable);
      // const params: { [x: string]: any } = store.getters.getParameters;
      const header = computed(() => {
        let types: { [x: string]: any } = VehicleParameters;
        return Object.keys(types).map(key => {
          return {[key]: types[key]}
        })
      });
      const convertData = computed(() => {
        let status: { [x: string]: string } = Status;
        let category: { [x: string]: string } = Category;
        return vehicles && vehicles.value && vehicles.value.content.map((o: any) => {
          return [
            o.model.brand.brand,// BRAND = 'Марка',
            // o.model.modelFull,// MODEL = 'Модель авто',
            o.model.model,// MODEL = 'Модель авто',
            o.desc.enginesL,// V_ENGINE = 'V двигателя',
            o.desc.fuelType.toLowerCase(),// FUEL_TYPE = 'Тип топлива',
            o.desc.hp,// POWER = 'Мощность',
            o.desc.vehYears,// RELEASE = 'Выпуск',
            o.desc.bodyType,// BODY_TYPE = 'Тип кузова',
            // o.vehicle+`, `+o.desc.driveType,// MODIFICATION = 'Модификация',
            o.desc.modBodyNum,// MODIFICATION = 'Модификация',
            o.id,
          ]
        })
      });

      function setDefaultData() {
        // params.forEach((o: any) => {
        //   search.value[o.type] = o.value.desc ? o.value.desc : o.value
        // });
        // store.commit(VehicleMutationTypes.GET_VEHICLES_BRANDS, null)
        // store.commit(VehicleMutationTypes.GET_VEHICLES, null)

      }
      //
      function previous() {
        if (pageable.value.page) {
          store.commit(VehicleMutationTypes.PREVIOUS_PAGE_VEHICLES, null);
          // scrollToTop()
        }
      }

      function next() {
        if (pageable.value.page + 1 !== vehicles.value.totalPages) {
          store.commit(VehicleMutationTypes.NEXT_PAGE_VEHICLES, null);
          // scrollToTop()
        }
      }

      onMounted(() => {
        store.commit(VehicleMutationTypes.SET_SIZE, 1000);
      })

      onUnmounted(() => {
        store.commit(VehicleMutationTypes.SET_SIZE, 20)
      })

      return {
        search,
        vehicles,
        // vehiclesBrands,
        pageable,
        header,
        convertData,
        previous,
        next,
        // clearState
      }
    },
    data: () => (
      {
        checkedAll: false as boolean,
        models: [],
        selectedVehicleBrand: {} as any,
        selectedVehicleModel: {} as any,
        selectedVehicleEngineValue: '' as string,
        selectedVehicleFuelType: '' as string,
        filteredVehicles: [] as any,
        filteredVehiclesBrand: [] as any,
        checked: [] as any,
        currentVehicleTable: [] as any,
        filteredVehiclesByEngine: [] as any,
        isLoading: false as boolean,
        brandPageable: {
          page: 0,
          totalElements: 0,
          totalPages: 0
        } as any
      }
    ),
    computed: {
      carAlreadyAdded() {
        return (carId: number): boolean => {
          return this.confirmed.findIndex((confirmedVehicleId: number) => {
            return confirmedVehicleId === carId
          }) >= 0
        }
      },
      getArticleId(): any {
        return this.$store.getters.getArticleCard.articleId
      },
      filteredConvertData() {
        return (vehiclesArr: any) => {
          this.currentVehicleTable = vehiclesArr.map((o: any) => {
            return [
              o.model.brand.brand,// BRAND = 'Марка',
              o.model.model,// MODEL = 'Модель авто',
              // o.model.modelFull,// MODEL = 'Модель авто',
              o.desc.enginesL,// V_ENGINE = 'V двигателя',
              o.desc.fuelType,// FUEL_TYPE = 'Тип топлива',
              o.desc.hp,// POWER = 'Мощность',
              o.desc.vehYears,// RELEASE = 'Выпуск',
              o.desc.bodyType,// BODY_TYPE = 'Тип кузова',
              // o.vehicle+`, `+o.desc.driveType,// MODIFICATION = 'Модификация',
              o.desc.modBodyNum,// MODIFICATION = 'Модификация',
              o.id,
            ]
          })

          return this.currentVehicleTable
        }
      },
      engineValues() {
        return () => {
          let arr = this.filteredVehicles.map((o: any) => {
            return o.desc.enginesL // V_ENGINE = 'V двигателя',
          }).sort(this.sortEngines)
          return [...new Set(arr)]
        }
      },
      fuelTypeValues() {
        return () => {
          let arr = this.filteredVehicles.map((o: any) => {
            return o?.desc?.fuelType?.toLowerCase()
          });
          return [...new Set(arr)]
        }
      },
    },
    methods: {
      async confirmVehicle() {
        this.isLoading = true
        await crossApplicab.postApplicabLocalVehicle(this.getArticleId, this.checked).then(async res => {
          let payload = {
            key: 'APPLICABILITY',
            value: await crossApplicab.getApplicability(String(this.$route.params.id))
          };
          this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        }).finally(() => {
          this.checked = []
          this.checkedAll = false
          this.isLoading = false
        })
      },
      setCheckedAll() {
        if (this.checkedAll) {
          this.checked = []
          this.checkedAll = false
        } else {
          this.checkedAll = true
          this.checked = []
          this.currentVehicleTable.map((car: any) => {
            const carId = car[car.length - 1]

            if (!this.carAlreadyAdded(carId)) {
              this.checked.push(carId)
            }
          })
        }
      },
      setChecked(item: any) {
        let index = this.checked.findIndex((o: string) => o === item);

        this.checkedAll = false

        if (index === -1) {
          this.checked.push(item)
        } else {
          this.checked.splice(index, 1);
        }
      },
      async update(item: string, value: any) {
        if (item === 'VEHICLE_BRAND') {

          if (value.id) {
            this.isLoading = true;
            this.selectedVehicleBrand = value;
            this.update('VEHICLE_MODEL', '')

            // TODO Добавить изменение квери "brandId"
            await vehicleBrands.getVehicleByBrandId(value.id, this.brandPageable.page).then((res: any) => {
              this.filteredVehiclesBrand  = res;
              this.brandPageable = {
                page: 0,
                totalElements: res.totalElements,
                totalPages: res.totalPages
              }
            })
          } else {
            this.update('VEHICLE_MODEL', '')
          }

          this.isLoading = false
        }
        if (item === 'VEHICLE_MODEL') {
          this.isLoading = true
          this.selectedVehicleModel = value
          this.selectedVehicleEngineValue = ''
          this.filteredVehiclesByEngine = []
          this.checked = []
          this.checkedAll = false
          console.log('VEHICLE MODEL ID', value.id)
          if (value.id) {
            this.filteredVehicles = await vehiclesRepository.getByBrandIdAndModelName(this.selectedVehicleBrand.id, this.selectedVehicleModel.model);
            // this.selectedVehicleModel = ''
          } else {
            this.filteredVehicles = []
          }
          this.isLoading = false
        }
        if (item === 'FUEL_TYPE') {
          this.selectedVehicleFuelType = value;
          this.checked = []
          this.checkedAll = false
          if (value) {
            this.filteredVehiclesByEngine = []
            if (this.selectedVehicleEngineValue && this.selectedVehicleEngineValue.length) {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.fuelType && fv.desc.fuelType.toLowerCase() === value && fv.desc.enginesL === this.selectedVehicleEngineValue);
            } else {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.fuelType && fv.desc.fuelType.toLowerCase() === value);
            }
          } else {
            if (this.selectedVehicleEngineValue && this.selectedVehicleEngineValue.length) {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.enginesL === this.selectedVehicleEngineValue);
            } else {
              this.filteredVehiclesByEngine = []
            }
          }
        }
        if (item === 'ENGINE_VALUE') {
          this.selectedVehicleEngineValue = value
          this.checked = []
          this.checkedAll = false
          if (value) {
            this.filteredVehiclesByEngine = []
            if (this.selectedVehicleFuelType && this.selectedVehicleFuelType.length) {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.enginesL === value && fv.desc.fuelType && fv.desc.fuelType.toLowerCase() === this.selectedVehicleFuelType);
            } else {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.enginesL === value);
            }
          } else {
            if (this.selectedVehicleFuelType && this.selectedVehicleFuelType.length) {
              this.filteredVehiclesByEngine = this.filteredVehicles.filter((fv: any) => fv.desc && fv.desc.fuelType && fv.desc.fuelType.toLowerCase() === this.selectedVehicleFuelType);
            } else {
              this.filteredVehiclesByEngine = []
            }
          }
        }
      },
      //

      modelNameSort(a: any, b: any) {
        if (a.model.toLowerCase() < b.model.toLowerCase()) {
          return -1;
        }
        if (a.model.toLowerCase() > b.model.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      sortEngines(a: any, b: any) {
        if (Number(a) < Number(b)) {
          return -1;
        }
        if (Number(a) > Number(b)) {
          return 1;
        }
        return 0;
      },
      async previousByBrands() {
        if (this.brandPageable.page > 0) {
          await vehicleBrands.getVehicleByBrandId(this.selectedVehicleBrand.id, --this.brandPageable.page).then((res: any) => {
            this.filteredVehiclesBrand  = res
          })
        }
      },
      async nextByBrands() {
        if (this.brandPageable.page < this.brandPageable.totalPages - 1) {
          await vehicleBrands.getVehicleByBrandId(this.selectedVehicleBrand.id, ++this.brandPageable.page).then((res: any) => {
            this.filteredVehiclesBrand  = res
          })
        }
      },
    }
    // beforeRouteLeave(to, from, next) {
    // this.clearState();
    // next()
    // }
  })
