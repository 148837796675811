
import {defineComponent, ref, watch, computed} from 'vue';
import {useStore} from '@/store'
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchEntities from '@/components/search/SearchEntities.vue';
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import pair from "@/services/pair";

export default defineComponent({
  name: 'Pairing',
  emits: ['close-modal'],
  components: {
    ModalWrapper,
    SearchEntities
  },
  props: {
    content: {
      type: Object,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const analog = ref({} as any);
    const side = ref({main: 'LEFT', analog: 'RIGHT'} as any);
    const searchText = ref('' as string);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const article = computed(() => store.getters.getArticleCard);
    const lrId = computed(() => {
      if (props.content) {
        return props.content.lrId
      } else {
        return 0
      }
    });

    watch(() => props.content, (val) => {
      if (val && val.leftArticleId === article.value.articleId) {
        analog.value = {...val.rightArticle};
        side.value.main = 'LEFT';
        side.value.analog = 'RIGHT'
      } else if (val) {
        analog.value = {...val.leftArticle};
        side.value.main = 'RIGHT';
        side.value.analog = 'LEFT'
      } else {
        setDefaultData()
      }
    });

    function setDefaultData() {
      analog.value = {};
      side.value = {main: 'LEFT', analog: 'RIGHT'}
    }

    function clickSelect() {
      if (side.value.main === 'LEFT') {
        side.value.analog = 'RIGHT'
      } else {
        side.value.analog = 'LEFT'
      }
    }

    function openSearchModal() {
      searchText.value = analog.value.article;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function updateEntity(entity: any) {
      analog.value = entity;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function actionPair() {
      let payload: { [x: string]: any } = {leftArticleId: 0, rightArticleId: 0};
      let analog_id = analog.value.articleId ? analog.value.articleId : analog.value.id ? analog.value.id : 0;
      if (side.value.main === 'RIGHT') {
        payload.leftArticleId = analog_id;
        payload.rightArticleId = article.value.articleId
      } else {
        payload.leftArticleId = article.value.articleId;
        payload.rightArticleId = analog_id
      }
      if (lrId.value) {
        pair.updatePair(String(lrId.value), payload).then(response => {
          response.leftArticle = side.value.main === 'RIGHT' ? analog.value : article.value;
          response.rightArticle = side.value.main === 'RIGHT' ? article.value : analog.value;
          store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, {key: 'PAIR', value: response});
          setDefaultData();
          emit('close-modal')
        }).catch(error => {
          store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
          throw error
        })
      } else {
        pair.createPair(payload).then(response => {
          response.leftArticle = side.value.main === 'RIGHT' ? analog.value : article.value;
          response.rightArticle = side.value.main === 'RIGHT' ? article.value : analog.value;
          store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {key: 'PAIR', value: response});
          setDefaultData();
          emit('close-modal')
        }).catch(error => {
          store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
          throw error
        })
      }
    }

    function deletePair() {
      pair.deletePair(String(lrId.value)).then(_ => {
        store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'PAIR', value: lrId.value});
        setDefaultData();
        emit('close-modal')
      }).catch(error => {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      })
    }

    return {
      searchText,
      article,
      analog,
      lrId,
      side,
      getRandomId,
      clickSelect,
      openSearchModal,
      updateEntity,
      actionPair,
      deletePair
    }
  }
})
