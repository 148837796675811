
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import video from '@/services/video';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import CustomSelectModal from '@/components/ui/CustomSelectModal.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';

export default defineComponent({
  name: 'AddVideoModal',
  emits: ['getNewVideos'],
  components: {
    BaseInput,
    ModalWrapper,
    CustomSelectModal,
    SmallSpinner
  },
  props: {
    marketplaces: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    linkToVideo: null as any,
    formDataFile: null as any,
    selectedMarketplace: null as any,
    isLoadingSavedFile: false as any,
    loadLink: false as any
  }),
  methods: {
    async addLink() {
      this.loadLink = true
      await video.addLinks({
        marketplaceId: this.selectedMarketplace.id,
        link: this.linkToVideo,
        articleId: String(this.$route.params.id)
      })
      this.linkToVideo = ''
      this.$emit('getNewVideos')
      this.loadLink = false
    },
    selectOption(marketplaceName: string) {
      this.selectedMarketplace = this.marketplaces.find((marketplace: any) => {
        return marketplace.name === marketplaceName
      })
    },
    async sendFile() {
      try {
        this.isLoadingSavedFile = true
        await video.addVideo(this.formDataFile)
        this.formDataFile = null
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
        this.$emit('getNewVideos')
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.isLoadingSavedFile = false
      }
    },
    parseTable(e: any) {
      let file = e.target.files;
      const formData = new FormData();
      const cid = localStorage.getItem('cid') as any
      formData.append('file', file[0]);
      formData.append('extension', file[0].type.split('/').pop());
      formData.append('articleId', String(this.$route.params.id));
      formData.append('cid', cid);
      formData.append('marketplaceId', this.selectedMarketplace.id);
      this.formDataFile = formData
    },
    clear() {
      this.linkToVideo = ''
      const inputFileRef = this.$refs.inputFile as any
      inputFileRef.value = ''
    }
  }
});
