import { httpClient } from '@/httpClient';

function all() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/marketplaces`).then((response) => response.data)
}

function getLinksArticleId(articleId: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/marketplaces/links/${articleId}`).then((response) => response.data)
}

function saveLink(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/marketplaces/links`, payload).then((response) => response.data)
}

function deleteLink(id: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/marketplaces/links/${id}`)
}

function updateLink(payload: any) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/marketplaces/links`, payload)
}

function createMarketplace(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/marketplaces`, payload)
}

function deleteMarketplace(id: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/marketplaces/${id}`)
}

function getImagesByMarketplaceId(marketplaceId: any, articleName: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/marketplaces/images/${marketplaceId}/${articleName}`)
    .then(response => {
      return response.data.map((image: any) => {
        return `${process.env.VUE_APP_URL_FILES}${image.split('\\').join('/')}`
      })
    })
}

function addImageForMarketplace(formData: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/marketplaces/images`, formData)
    .then(({ data }: any) => {
      return `${process.env.VUE_APP_URL_FILES}${data.split('\\').join('/')}`
    })
}

function deleteImageMarketplace(articleId: any, imageName: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/marketplaces/images?articleId=${articleId}&imageName=${imageName}`)
}

export default {
  all,
  deleteLink,
  saveLink,
  getLinksArticleId,
  updateLink,
  createMarketplace,
  deleteMarketplace,
  getImagesByMarketplaceId,
  addImageForMarketplace,
  deleteImageMarketplace,
}
