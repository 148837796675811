
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import ozonImages from "@/services/ozonImages";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  name: 'SelectImageModal',
  emits: ['saveImages', 'closeModal'],
  components: { ModalWrapper, Spinner },
  data: () => ({
    images: [] as any,
    pickedImages: [] as any,
    isLoading: true as boolean,
  }),
  computed: {
    checkImage(): any {
      return (image: any) => {
        const idx = this.pickedImages.findIndex((pickedImage: any) => pickedImage === image)
        return idx !== -1 ? 'select-image-modal__active-image' : ''
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    saveImages() {
      this.$emit('saveImages', this.pickedImages)
      this.closeModal()
    },
    pickImage(image: any) {
      if (this.pickedImages.findIndex((pickedImage: any) => pickedImage === image) !== -1) {
        this.pickedImages = this.pickedImages.filter((pickedImage: any) => pickedImage !== image)
        return;
      }
      this.pickedImages.push(image)
    }
  },
  async mounted() {
    if (this.$store.getters.getArticleCard?.article) {
      this.images = await ozonImages.getImageForArticle(this.$store.getters.getArticleCard.article)
      this.isLoading = false
    }
  }
})
