
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import debounce from "@/components/debounce";

export default defineComponent({
  name: 'BaseBadge',
  props: {

  },
  methods: {
  }
})
