
import { defineComponent } from 'vue';
import ozon from "@/services/ozon";
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";
import MassSelect from "@/components/article-card/ozon/MassSelect.vue";
import ImageSelect from "@/components/article-card/ozon/ImageSelect.vue";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";

export default defineComponent({
  name: 'OzonParamsWrapper',
  components: {
    BaseSelect,
    BaseIcon,
    MassSelect,
    ImageSelect
  },
  props: {
    params: {
      type: Object,
      default: () => null
    },
    article: {
      type: String,
      required: true,
      default: () => ''
    }
  },
  data: () => ({
    addStatusProduct: null as any,
    editMode: false as boolean,
    categories: null as any,
    addedYet: {} as any,
    categoryParams: null as any,
    newOzonArticle2: {} as any,
    paramList: {
      barcode: {
        key: 'Штрихкод (название артикула)' as string,
        required: true,
      },
      description: {
        key: 'Описание товара' as string,
        required: false,
      },
      category_id: {
        key: 'Категория' as string,
        required: true,
      },
      name: {
        key: 'Название товара' as string,
        required: false,
      },
      offer_id: {
        key: 'Идентификатор товара(название артикула)' as string,
        required: false,
      },
      price: {
        key: 'Цена товара с учетом скидок, отображается на карточке товара. Если на товар нет скидок — укажите значение old_price.' as string,
        required: true,
      },
      old_price: {
        key: 'Цена до скидок (будет зачеркнута на карточке товара)' as string,
        required: false,
      },
      premium_price: {
        key: 'Цена с подпиской Ozon Premium' as string,
        required: false,
      },
      vat: {
        key: 'Ставка НДС для товара (0 — без НДС; 0.1 — 10%; 0.2 — 20%)' as string,
        required: true,
      },
      vendor: {
        key: 'Бренд(Производитель)' as string,
        required: true,
      },
      // vendor_code:
      // {
      //   key: 'Артикул(название артикула)' as string,
      //   required: true,
      // },
      height: {
        key: 'Высота упаковки' as string,
        required: true,
      },
      depth: {
        key: 'Длина упаковки' as string,
        required: true,
      },
      width: {
        key: 'Ширина упаковки' as string,
        required: true,
      },
      dimension_unit:         {
        key: 'Единица измерения габаритов(mm (миллиметры), cm (сантиметры), in (дюймы))' as string,
        required: true,
      },
      weight: {
        key: 'Вес товара в упаковке (предельное значение - 1000 килограмм)' as string,
        required: true,
      },
      weight_unit: {
        key: 'Единицы измерения веса (g (граммы), kg (килограммы), lb (фунты))' as string,
        required: true,
      },
      images: {
        key: 'Изображения' as string,
        required: true,
      },
      attributes: {
        key: 'Параметры' as string,
        required: true,
      },
    } as any,
    savedOzonArticle: 0 as number,
    savedOzonTaskId: 0 as number,
    savedOzonArticleInfo: null as any,
    savedOzonArticleTask: null as any,
    newOzonArticle: {
      // Тестовый объект на ОЗОН
      barcode: '' as string,
      description: '' as string,
      category_id: 0 as number,
      name: '' as string,
      offer_id: '000000' as string,
      price: '' as string,
      old_price: '' as string,
      premium_price: '' as string,
      vat: '0.2' as string,
      vendor: 'FENOX' as string,
      height: 0,
      depth: 0,
      width: 0,
      dimension_unit: 'mm' as string,
      weight: 0,
      weight_unit: 'g' as string,
      images: [],
      attributes: [
        {
          id: 85,
          values: [
            {
              dictionary_value_id: 115861476,
              value: 'Fenox'
            }
          ],
        } as any,
      ]
    } as any,
    attributes: {} as any
  }),
  computed: {
    articleName(): any {
      return this.$store.getters.getArticleCard.article
    },
    checkRequiredFields(): any {
        let isBtnDisabled = false

        if (!(this.categoryParams && this.categoryParams.length)) {
          return true
        } else {
          // проверка полей правой части
          this.categoryParams.map((param: any) => {
            if (!isBtnDisabled) {
              if (param.is_required) {
                if (param.id === 4194) {
                  isBtnDisabled = false
                  return;
                }

                const checkCategoryParam = Object.keys(this.newOzonArticle2).findIndex((key: any) => Number(key) === param.id && this.newOzonArticle2[key].length)
                const checkAttr = this.newOzonArticle.attributes.findIndex((key: any) => Number(key.id) === param.id)
                if (checkCategoryParam === -1 && checkAttr === -1) {
                  isBtnDisabled = true
                }
              }
            }
          })
        }
        // проверка полей левой части
        Object.keys(this.newOzonArticle).map((field: any) => {
          if (!isBtnDisabled) {
            if (this.paramList[field] && this.paramList[field].required) {
              if (field === 'images') {
                isBtnDisabled = false
                return;
              }

              if (typeof this.newOzonArticle[field] === 'number' && this.newOzonArticle[field] !== 0) {
                isBtnDisabled = false
                return;
              }

              isBtnDisabled = !(
                  this.newOzonArticle[field] &&
                  this.newOzonArticle[field].length) &&
                  this.newOzonArticle[field] !== 0
            }
          }
        })

        return isBtnDisabled
    }
  },
  methods: {
    addSavedImages(savedImages: any) {
      this.newOzonArticle.images = savedImages.map((img: any) => img)
    },
    addSavedImagesForCategory(savedImages: any, paramId: any) {
      const index = this.newOzonArticle.attributes.findIndex((attr: any) => attr.id === paramId)
      const images = savedImages.map((img: any) => {
        return {
          value: img
        }
      })

      if (index === -1) {
        this.newOzonArticle.attributes.push({
          id: paramId,
          values: [...images]
        })
      } else {
        this.newOzonArticle.attributes[index] = {
          id: paramId,
          values: [
            ...images
          ]
        }
      }
    },
    clearSavedImages(image: any) {
      this.newOzonArticle.images = this.newOzonArticle.images.filter((img: any) => img !== image)
    },
    clearAttrByValue(event: any, paramId: any) {
      const idxAttr = this.newOzonArticle.attributes.findIndex((attr: any) => attr.id === paramId)

      this.newOzonArticle.attributes[idxAttr] = {
        id: paramId,
        values: [
          ...this.newOzonArticle.attributes[idxAttr].values.filter((val: any) => val.value !== event)
        ]
      }

      if (!this.newOzonArticle.attributes[idxAttr].values.length) {
        this.filterAttr(paramId)
      }
    },
    clearAttrByDictionaryValueId(event: any, paramId: any) {
      const idxAttr = this.newOzonArticle.attributes.findIndex((attr: any) => attr.id === paramId)
      this.newOzonArticle.attributes[idxAttr] = {
        id: paramId,
        values: [
          ...this.newOzonArticle.attributes[idxAttr].values.filter((val: any) => val.dictionary_value_id !== event.id)
        ]
      }

      if (!this.newOzonArticle.attributes[idxAttr].values.length) {
        this.filterAttr(paramId)
      }
    },
    clearConfirmedParametersArticle(paramId: any) {
      this.newOzonArticle2[paramId] = ''
    },
    filterAttr(paramId: any) {
      this.newOzonArticle.attributes = this.newOzonArticle.attributes.filter((attr: any) => attr.id !== paramId)
    },
    async getParams(id: number) {
      this.attributes[id] = []
      let params = await ozon.getAttributeValueByCategory(this.newOzonArticle.category_id, id)
      this.attributes[id] = params
    },
    sortByRequire(a: any, b: any) {
      if (a.is_require < b.is_require) {
        return 1;
      }
      if (a.is_require > b.is_require) {
        return -1;
      }
      return 0;
    },
    async update(item: string, value: any, paramId: number) {
      if (item === 'OZON_CATEGORY') {
        this.newOzonArticle.category_id = value.categoryId
        let params = await ozon.getAttributesByCategory(value.categoryId)
        this.categoryParams = params.result
        this.categoryParams.forEach((cp: any) => {
          if (Number(cp.dictionary_id) && (cp.id !== 85) && (cp.id !== 7212) && (cp.id !== 88) && (cp.id !== 9461)) {
            this.getParams(cp.id)
          }
          if ((cp.id === 7236)) {
            // Партномер (артикул производителя)
            this.newOzonArticle2['7236'] = this.article
          }

          if ((cp.id === 9024)) {
            // Артикул
            this.newOzonArticle2['9024'] = this.article
          }
        })
      } else if (item === 'OZON_CATEGORY_PARAMETER') {
        if (paramId === 7210 || paramId === 7204 || paramId === 7206 || paramId === 7367) {
          const index = this.newOzonArticle.attributes.findIndex((attr: any) => attr.id === paramId)

          if (index === -1) {
            this.newOzonArticle.attributes.push({
              id: paramId,
              values: [
                {
                  dictionary_value_id: value.id,
                }
              ]
            })
          } else {
            this.newOzonArticle.attributes[index] = {
              id: paramId,
              values: [
                ...this.newOzonArticle.attributes[index].values,
                {
                  dictionary_value_id: value.id,
                }
              ]
            }
          }
          return
        }

        this.newOzonArticle.attributes = this.newOzonArticle.attributes.filter((attr: any) => attr.id !== paramId)

        this.newOzonArticle.attributes.push( {
          id: paramId,
          values: [
            {
              dictionary_value_id: value.id,
              // value: value.value
            }
          ]
        })
      } else if (item === 'CONFIRMED_ARTICLE_PARAMETER') {
        this.newOzonArticle2[paramId] = value.value
      } else if (item === 'OTHER_PARAM') {
        const index = this.newOzonArticle.attributes.findIndex((attr: any) => attr.id === paramId)

        if (index === -1) {
          this.newOzonArticle.attributes.push({
            id: paramId,
            values: [
              { value }
            ]
          })
        } else {
          this.newOzonArticle.attributes[index] = {
            id: paramId,
            values: [
              ...this.newOzonArticle.attributes[index].values,
              { value }
            ]
          }
        }
      }
    },
    async addToOzon() {
      if (Object.keys(this.newOzonArticle2).length) {
        Object.keys(this.newOzonArticle2).forEach((key: string) => {
          if (!this.newOzonArticle.attributes.some((el: any) => el.id === Number(key))) {
            this.newOzonArticle.attributes.push({id: Number(key), values: [{value: this.newOzonArticle2[key]}]})
          }
        })
      }

      try {
        await ozon.addToOzon(Number(this.$route.params.id), this.newOzonArticle)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        await this.checkOzonArticle()
        this.editMode = false
      } catch (e) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
    async getTaskInfo(taskId: number) {
      let res = await ozon.getTaskInfo(taskId)
      this.savedOzonArticleTask = res
    },
    async getProductInfo(ozonId: number) {
      let res = await ozon.getProductInfo(ozonId)
      this.savedOzonArticleInfo = res
    },
    async editProduct(ozonId: any) {
      this.editMode = !this.editMode

      await this.getProductInfo(ozonId)

      const { category_id, images } = this.savedOzonArticleInfo
      await this.update('OZON_CATEGORY', { categoryId: category_id }, 0)

      //устанавливаем значения для  полей, по которым есть информация
      Object.keys(this.savedOzonArticleInfo).map((field: any) => {
        if (this.newOzonArticle[field] !== undefined) {
          this.newOzonArticle[field] = this.savedOzonArticleInfo[field]
        }
      })
    },
    async checkOzonArticle() {
      let res = await ozon.findByArticleId(Number(this.$route.params.id))

      if (res && res.ozonId) {
        this.savedOzonArticle = res.ozonId
      }
      if (res && res.ozonTaskId) {
        this.savedOzonTaskId = res.ozonTaskId
      }
    }
  },
  async mounted() {
    this.categories = await ozon.getOzonCategory()
    this.checkOzonArticle()
    this.newOzonArticle.barcode = this.article
    this.newOzonArticle.offer_id = this.article
  }
})
