
  import {defineComponent, computed} from 'vue';
  import {useStore} from '@/store'
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
  import router from "@/router";
  import {VehicleMutationTypes} from "@/store/modules/vehicles/mutation-types";

  export default defineComponent({
    name: 'VehiclesRow',
    components: {
      BaseIcon,
      BaseCheckbox
    },
    props: {
      disabledCheckbox: {
        type: Boolean,
        default: () => false
      },
      isUsersRow: {
        type: Boolean,
        default: () => false
      },
      disabledHeaderCheckbox: {
        type: Boolean,
        default: () => false
      },
      id: {
        default: () => null
      },
      defaultCheckbox: {
        type: Boolean,
        default: () => false
      },
      row: {
        type: Array,
        required: true,
        default: () => []
      },
      isHeader: {
        type: Boolean,
        default: () => false
      },
      vehicleState: {
        type: String,
        default: () => ''
      },
      checked: {
        type: Boolean,
        default: () => false
      },
      isLink: {
        type: Boolean,
        default: () => true
      }
    },
    setup() {
      const store = useStore();
      const sort = computed(() => store.getters.getSort);
      const getHeaderName = computed(() => {
        return (item: object) => Object.values(item)[0]
      });
      const getBodyName = computed(() => {
        return (item: any) => {
          if (item && typeof item === 'object') return item.name;
          else return item
        }
      });

      function setSort() {
        let payload = sort.value === 'asc' ? 'desc' : 'asc';
        store.commit(VehicleMutationTypes.SET_SORT, payload)
      }

      function goToArticle(item: any) {
        // if (item.id) router.push({name: 'article', params: {id: item.id}})
      }

      return {sort, getHeaderName, getBodyName, setSort, goToArticle}
    },
    methods: {
      goToVehicle(item: number) {
        if (Number(item) === item && this.isLink) {
          this.$router.push({name: 'vehicle', params: {carId: item}})
        }
      },
      checkModel() {
        console.log('check_model')
        this.$emit('setChecked')
      }
    }
  })
