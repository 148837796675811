
import { defineComponent } from 'vue';
import BaseInput from '@/components/ui/BaseInput.vue';

export default defineComponent({
  name: 'SearchDropdownOptions',
  components: {
    BaseInput
  },
  data: () => ({
    customValue: '' as string,
  }),
  props: {
    defaultCustomValue: {
      type: String,
      default: () => null
    },
    showCreateBtn: {
      type: Boolean,
      default: () => true
    },
    showInput: {
      type: Boolean,
      default: () => true
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    options: {
      type: Object,
      default: () => []
    },
    type: {
      type: String,
      default: () => ''
    },
  },
  computed: {
    getCustomValue(): string {
      let text = 'Добавить'

      text = this.type === 'VEHICLE_BRAND' ? 'Создать бренд' : text
      text = this.type === 'VEHICLE_MODEL' ? 'Создать модель' : text
      text = this.type === 'VEHICLE_FULL_MODEL' ? 'Создать модель' : text

      return `${text} "${this.customValue}"`
    },
    getOptionName() {
      return (option: any) => {
        if (!option) {
          return
        }

        if (this.type && this.type === 'VEHICLE_BRAND') {
          return option && option.brand
        } else if (this.type && this.type === 'VEHICLE_FULL_MODEL') {
          return option && option.modelFull
        } else if (this.type && this.type === 'VEHICLE_MODEL') {
          return option && option.model
        } else if (this.type && this.type === 'YEAR_OF_PRODUCTION') {
          return option
        } else if (this.type && this.type === 'ENGINE_POWER') {
          return option
        } else if (this.type && this.type === 'FUEL_TYPE') {
          return option
        } else if (this.type && this.type === 'ENGINE_VALUE') {
          return option
        } else if (this.type && this.type === 'VEHICLE_MODIFICATION') {
          return option
        } else if (this.type && this.type === 'DRIVE_TYPE') {
          return option
        } else if (this.type && this.type === 'BODY_TYPE') {
          return option
        } else if (this.type && this.type === 'REGION') {
          return option && option.region
        } else if (this.type && this.type === 'COUNTRY') {
          return option && option.name
        }else {
          return option
        }
      }
    }
  },
  methods: {
    update(event: any) {
      this.$emit('update', event, this.type)
    },
    choseOption(event: any) {
      let currentTarget = event.target.closest('.search-dropdown-options__list-item');
      if (currentTarget && currentTarget.dataset && currentTarget.dataset.index) {
        let index = currentTarget.dataset.index;
        if (Number(index) === 666) {

          if (this.type === 'VEHICLE_MODEL') {
            this.$emit('pickedCustomOption', {
              value: this.customValue,
              action: 'CREATE_MODEL'
            })
            return;
          }

          if (this.type === 'VEHICLE_BRAND') {
            this.$emit('pickedCustomOption', {
              value: this.customValue,
              action: 'CREATE_BRAND'
            })
            return;
          }

          this.$emit('pickedCustomOption', this.customValue)
        } else if (index !== -1) {
          this.$emit('pickedOption', this.options[index])
        }
      }
    }
  },
  watch: {
    defaultCustomValue() {
      if (this.defaultCustomValue && this.defaultCustomValue.length) {
        this.customValue = this.defaultCustomValue
      }
    }
  }
})
