
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { LogisticParams, LogisticParamsUnits } from '@/store/modules/article-card/action-types';
import BaseInput from '@/components/ui/BaseInput.vue';
import logistic from '@/services/logistic';
import Barcodes from '@/components/article-card/barcodes/Barcodes.vue';
import BarcodesWrapper from '@/components/article-card/barcodes/BarcodesWrapper.vue';

export default defineComponent({
  name: 'LogisticParams',
  components: {
    BarcodesWrapper,
    BaseTable,
    BaseInput,
    Barcodes,
  },
  data: () => ({
    logisticParams: [] as any,
    dataLogistic: {} as any,
    copyDataLogistic: null as any
  }),
  computed: {
    isDisabledBtn(): any {
      return JSON.stringify(this.dataLogistic) === JSON.stringify(this.copyDataLogistic)
    },
    articleName(): any {
      return this.$store.getters.getArticleCard.article
    },
    params(): any {
      return Object.keys(LogisticParams)
    },
    headers(): any {
      return [
        {
          title: 'Параметры',
          content: (item: any) => LogisticParams[item as keyof typeof LogisticParams]
        },
        {
          title: 'Значение',
          content: (item: any) => {
            const unit = LogisticParamsUnits[item as keyof typeof LogisticParams]

            if (unit === LogisticParamsUnits.BULK) {
              const [firstLetter, secondLetter] = unit

              return `
                <div>${firstLetter}</div>
                <sup style="line-height: 1rem">${secondLetter}</sup>
              `
            }

            return unit
          }
        },
      ]
    },
  },
  methods: {
    update(text: any, field: any) {
      if (!text.trim().length) {
        this.dataLogistic[field] = null
      } else {
        this.dataLogistic[field] = +text
      }
    },
    async saveParams(paramName: any) {
      const payload = {
        articleId: this.$store.getters.getArticleCard.articleId,
        params: [
          ...this.params
            .filter((paramName: string) => paramName !== 'BULK')
            .map((paramName: any) => {
              return {
                param: paramName,
                value: this.dataLogistic[paramName] || 0
              }
            })
        ]
      }

      const newDataLogisticParams = await logistic.setLogistic(payload)

      Object
        .keys(newDataLogisticParams)
        .map((paramKey: string) => {
          this.dataLogistic[paramKey] = newDataLogisticParams[paramKey] === 0
            ? null
            : newDataLogisticParams[paramKey]
        })

      this.copyDataLogistic = { ...this.dataLogistic }
    },
  },
  async mounted() {
    this.params.map((paramName: any) => {
      this.dataLogistic[paramName] = null
    })

    this.logisticParams = await logistic.getLogisticArticle(this.$store.getters.getArticleCard.articleId)

    this.logisticParams.map(({param, value}: any) => {
      this.dataLogistic[param] = value === 0
        ? null
        : value
    })

    this.copyDataLogistic = { ...this.dataLogistic }
  }
});
