
import {defineComponent, ref, computed} from 'vue';
import { store, useStore } from '@/store';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchEntities from '@/components/search/SearchEntities.vue';
import articles from "@/services/articles";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import BaseError from '@/components/ui/BaseError.vue';
import {roles} from "@/services/roles/serviceRoles";
import cross from "@/services/cross";

export default defineComponent({
  name: 'AddCross',
  emits: ['close-modal'],
  components: {
    BaseCheckbox,
    BaseError,
    ModalWrapper,
    SearchEntities
  },
  data: () => ({
    errors: [] as Array<any>,
  }),
  props: {
    confirmed: {
      type: Object,
      default: () => [] as any
    }
  },
  methods: {
    addCross() {
      this.errors = []
      let payload = {
        brandId: this.result.brand.brandId,
        brand: this.result.brand.brand,
        brandArt: this.result.brandArt,
        direction: this.result.direction
      };
      cross.addCross(this.article.articleId, payload).then(async _ => {
        let payload = {key: 'CROSS', value: await cross.getAllCrosses(this.article.articleId)};
        this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(error => {
        if (error.data) this.errors.push(error.data);
        throw error
      }).finally(() => {
        this.$emit('close-modal')
      })
    },
    addDuplicateCrossToDB() {
      this.errors = []
      let payload = {
        brandId: this.result.brand.brandId,
        brand: this.result.brand.brand,
        brandArt: this.result.brandArt,
        direction: this.result.direction
      };
      cross.addDuplicateCrossToDB(this.article.articleId, payload).then(async _ => {
        let payload = {key: 'CROSS', value: await cross.getAllCrosses(this.article.articleId)};
        this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
      }).catch(error => {
        if (error.data) this.errors.push(error.data);
        throw error
      })
    }
  },
  watch: {
    'result.brand.brand': function(val) {
      if (this.errors.length) {
        this.errors = []
      }
    },
    'result.brandArt': function(val) {
      if (this.errors.length) {
        this.errors = []
      }
    }
  },
  computed: {
    isShowAddDuplicateBtn(): any {
      return this.errors.some((error: any) => error.error === 'cross_exist')
    },
    convertErrors() {
      let result = [] as any;
      this.errors.forEach((error: any) => {
        if (error.error === 'cross_exist') {
          let text = `Кросс <span style="color: #4F5058;">${this.result.brand.brand} ${this.result.brandArt}</span> уже добавлен
            ${error.articleId === this.article.articleId || this.confirmed.some((e: any) => e.brand === this.result.brand.brand && e.brandArt === this.result.brandArt) ? 'в этот артикул' : ' в артикул <span style="color: #4F5058;">' + error.article + '</span>'}`;
          result.push(text)
        } else if (error.error === 'brand_not_found') {
          let text = `Бренд <span style="color: #4F5058;">${error.brand} (${error.brandArt})</span> не найден`;
          result.push(text)
        } else if (error && error[0]) { // если ошибка прилетела с проверки маски
          error.map(({number, regex, value}: {number: number, regex: string, value: string}) => {
            if (value && !regex) {
              const text = `<span style="color: #4F5058;">Неккоретное кол-во символов</span>`
              result.push(text)
              return;
            }

            const text = `<span style="color: #4F5058;">Неккоректный символ №${number} (${value}). Должно быть: ${regex}</span>`
            result.push(text)
          })
        }
      });
      return result
    },
    getUserRole(): any {
      return this.$store.getters.getUserRole
    },
    userCanAddDuplicateCross(): any {
      return this.$store.getters.getAllPermissions.add_duplicate_cross[this.$store.getters.getUserRole]
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    // CM (по умолчанию)
    const directions = ref([
      {key: 'CM', value: 'Жесткие', checked: true},
      {key: 'OE', value: 'OE', checked: false},
      {key: 'ANALOG', value: 'Аналоги', checked: false}
    ]);
    const searchText = ref('' as string);
    const result = ref({direction: 'CM', brandArt: '', brand: {} as any});
    const article: { [x: string]: any } = computed(() => store.getters.getArticleCard);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));

    function setDefaultData() {
      directions.value = [{key: 'OE', value: 'OE', checked: false}, {key: 'CM', value: 'CM', checked: true}];
      searchText.value = '';
      result.value = {direction: 'CM', brandArt: '', brand: {} as any}
    }

    function clickDirection(item: any) {
      result.value.brand = {};
      result.value.direction = item.key;
      directions.value.forEach((o: any) => {
        o.checked = o.key === item.key
      })
    }

    function openSearchModal() {
      searchText.value = result.value.brand.brand;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function updateEntity(entity: any) {
      result.value.brand = entity;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    return {
      directions,
      searchText,
      result,
      article,
      getRandomId,
      clickDirection,
      openSearchModal,
      updateEntity,
    }
  }
})
