
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupFieldItem from "@/components/commodity-groups/CommodityGroupFieldItem.vue";
import marketplaces from '@/services/marketplaces';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

export default defineComponent({
  name: 'AddMarketplaceModal',
  emits: [
    'addNewParam',
    'closeAddParamModal',
    'addedNewParam',
    'closeModal',
    'refresh'
  ],
  components: {
    BaseCheckbox,
    ModalWrapper,
    CommodityGroupFieldItem
  },
  data: () => ({
    error: null as any,
    dataNewParam: {
      name: null as any,
    } as any,
    isSigned: false as any
  }),
  methods: {
    closeModal() {
      this.dataNewParam.name = ''
      this.$emit('closeModal')
    },
    setDataCommodityGroup(fieldName: string, value: string) {
      this.dataNewParam[fieldName] = value
    },
    async addMarketplace() {
      const payload = {
        name: this.dataNewParam.name,
        isSigned: this.isSigned,
      }

      await marketplaces.createMarketplace(payload)
      this.$emit('closeModal')
      this.$emit('refresh')
    },
  },
})
