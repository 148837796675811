
import {defineComponent, ref, computed} from 'vue';
import {useStore} from '@/store'
import TreeItem from './TreeItem.vue'
import AddTreeElement from '../../modal-blocks/AddTreeElement.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import articles from "@/services/articles";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import SelectionSlot from '@/components/modals/SelectionSlot.vue'
import tree from "@/services/tree";

export default defineComponent({
  name: 'Tree',
  components: {
    TreeItem,
    AddTreeElement,
    ModalWrapper,
    SelectionSlot
  },
  props: {
    branches: {
      type: Array,
      default: () => []
    },
    treeId: {
      type: Number,
      default: () => 0
    }
  },
  setup(props) {
    const store = useStore();
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const parentId = ref(null as any);
    const node = ref(null as any);

    function openDeleteModal(item: any) {
      node.value = item;
      let element = document.getElementById(`modal-delete-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function actionModal() {
      if (node.value.parentId) {
        tree.deleteNode(String(props.treeId), node.value.nodeId).then(_ => {
          let payload = {treeId: props.treeId, nodeId: node.value.nodeId};
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'TREE', value: payload});
          closeModal()
        })
      } else {
        tree.deleteTree(String(props.treeId)).then(_ => {
          let payload = {treeId: props.treeId, nodeId: null};
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'TREE', value: payload});
          closeModal()
        })
      }
    }

    function makeFolder(item: any) {
      item.children = [];
      addItem(item)
    }

    function addItem(item: any) {
      parentId.value = item.articleId;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeAddTreeElementModal() {
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function closeModal() {
      node.value = null;
      let element = document.getElementById(`modal-delete-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    return {
      getRandomId,
      parentId,
      openDeleteModal,
      actionModal,
      makeFolder,
      addItem,
      closeAddTreeElementModal,
      closeModal
    }
  }
})
