
import { defineComponent } from 'vue';
import BarcodesList from '@/components/article-card/barcodes/BarcodesList.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import Barcodes from '@/components/article-card/barcodes/Barcodes.vue';
import MassSelect from '@/components/article-card/ozon/MassSelect.vue';
import articles from '@/services/articles';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'BarcodesWrapper',
  props: {
    items: {
      type: Array,
      default: [],
    }
  },
  components: {
    MassSelect,
    BaseIcon,
    BarcodesList,
    Barcodes,
  },
  data: () => ({
    barcodes: [] as string[],
    individualBarcodesError: false,
    groupsBarcodesError: false,
    article: null as any,
    isLoading: false,
  }),
  computed: {
    getIndividualBarcodes(): string[] {
      return this.barcodes.filter((barcode: string) => barcode.length === 13)
    },
    getGroupsBarcodes(): string[] {
      return this.barcodes.filter((barcode: string) => barcode.length === 14)
    }
  },
  methods: {
    update(value: string) {
      this.barcodes.push(value)
    },
    deleteBarcode(barcode: string) {
      const barcodeIndex = this.barcodes.indexOf(barcode)

      if (barcodeIndex >= 0) {
        this.barcodes.splice(barcodeIndex, 1)
      }
    },
    async save() {
      this.isLoading = true
      try {
        await articles.updateBarcodes(this.article.articleId, this.barcodes)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.article = this.$store.getters.getArticleCard
    this.barcodes = this.article.barcodes || []
  }
});
