
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { Certificate, CertificateTypes } from '@/models/CertificateModel';
import { ConvertDate } from '@/components/converters/date';
import Spinner from '@/components/Spinner.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';

export default defineComponent({
  name: 'ArticleCertificate',
  components: {
    Spinner,
    BaseTable,
    DropdownEllipsis,
  },
  data: () => ({
  }),
  methods: {
    async deleteCertificate(item: Certificate) {
      await this.$store.dispatch('deleteCertificate', item.id)
    },
    downloadFile(url: string) {
      const href = `${process.env.VUE_APP_URL_FILES}${url.split('\\').join('/')}`
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    isRefusalType() {
      return (item: Certificate) => {
        return item.type === CertificateTypes.REFUSAL
      }
    },
    headers() {
      return [
        {
          title: 'Номер',
          content: (item: Certificate) => item.number
        },
        {
          title: 'Срок действия',
          content: (item: Certificate) => {
            if (this.isRefusalType(item)) {
              return '-'
            }
            if (item.fileDate && item.expiredAt) {
              return `${ConvertDate(item.fileDate)} - ${ConvertDate(item.expiredAt)} ${item.isExpired? '(просрочен)' : ''}`
            } else {
              return `${ConvertDate(item.expiredAt)} ${item.isExpired ? '(просрочен)' : ''}`
            }
          }
        },
        {
          title: 'Автор',
          content: (item: Certificate) => item.user
        },
        {
          title: 'Ссылка на файл',
          content: (item: Certificate) => item.link ? 'Скачать файл' : 'Файл не добавлен'
        },
      ]
    },
    certificates(): any {
      return this.$store.getters.getCertificates
    },
    isLoadingCertificates(): boolean {
      return this.$store.getters.loadingCertificates
    },
    dropdownOptions() {
      return (item: Certificate): any => [
        {
          name: 'Удалить',
          method: () => this.deleteCertificate(item)
        },
        {
          name: 'Скачать',
          method: () => this.downloadFile(item.link)
        }
      ]
    },
  },
});
