import {httpClient} from "@/httpClient";

function getAllByType(type: string, articleId: string, page?: string | number) {
  return httpClient.get(`/audit/${type}?id=${articleId}&pageSort=date&sort=auditedAt,asc&page=${page}&size=500`).then((response) => response.data)
}

function getAllByTypeAndIdAndDate(type: string, articleId: string, page?: string | number, dateFrom?: any, dateTo?:any) {
  const convertDate = dateTo.split('-').splice(0, 2)
  convertDate.push(String(Number(dateTo.split('-')[2]) + 1))
  return httpClient.get(`/audit/${type}?from=${dateFrom}&to=${convertDate.join('-')}&id=${articleId}&pageSort=date&sort=auditedAt,asc&page=${page}&size=500`).then((response) => response.data)
}

export default {
  getAllByType,
  getAllByTypeAndIdAndDate
}
