
import { defineComponent } from 'vue';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import articles from '@/services/articles';

export default defineComponent({
  name: 'SuitableTree',
  components: {
    BaseIcon
  },
  methods: {
    async deleteClone(cloneId: any) {
      const newClones = this.clones.filter((clone: any) => {
        return clone.articleId !== cloneId
      })

      await articles.updateClone(
        {
          articleId: this.article.articleId,
          cloneIds: [...newClones.map((clone: any) => clone.articleId)]
        }
      )

      this.setClones(newClones)
    },
    setClones(value: any) {
      this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {
        key: 'TREE',
        isSuitableTree: true,
        value: [...value]
      })
    }
  },
  computed: {
    article(): any {
      return this.$store.getters.getArticleCard as any
    },
    clones() {
      const params = this.$store.getters.getParamsCard
      const treeParam = params.find((param: any) => param.key === 'TREE')
      return treeParam.suitableTree
    }
  },
  mounted() {
    this.setClones(this.article.clones)
  }
})
