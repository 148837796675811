
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseIcon from '../../ui/BaseIcon.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import Spinner from "@/components/Spinner.vue";
import HistoryPage from "@/components/article-card/history/HistoryPage.vue";
import DropdownActions from "@/components/article-card/history/DropdownActions.vue";
import articles from "@/services/articles";

export default defineComponent({
  name: 'HistoryWrapper',
  components: { BaseDatePicker, BaseIcon, Spinner, HistoryPage, DropdownActions },
  props: {
    changeByType: {
      type: String,
      required: true,
      default: () => null
    },
    id: {
      type: String,
      required: true,
      default: () => null
    }
  },
  data: () => ({
    contentLength: 0 as number,
    isContentEmpty: true,
    dateRange: null,
    isLastPage: false as boolean,
    isShowHistory: false as boolean,
    dataChanges: [] as any,
    content: [] as any,
    crossApplicab: [] as any,
    deleteCrossApplicab: [] as any,
    infoPaging: {
      totalPaged: null as any,
      currentPage: 0
    },
    dropdownOptions: {
      authors: [] as any,
      actions: ['Все', 'Артикул', 'Кроссировка', 'Параметры', 'Входит в - Состоит из', 'Применяемость']
    },
    filters: {
      author: null as any,
      changes: null as any
    }
  }),
  computed: {
    articleName(): any {
      return this.$store.getters.getArticleCard.article
    }
  },
  methods: {
    setDateRange(range: any) {
      this.dateRange = range
      this.infoPaging.currentPage = 0
      this.contentLength = 0
      this.filters.author = 'Все'
      this.filters.changes = 'Все'
    },
    setPageable(pageable: any) {
      const {pageNumber, totalPages} = pageable

      if ((pageNumber + 1) === totalPages) {
        this.isLastPage = true
      } else {
        this.isLastPage = false
      }
    },
    setOptions(options: any) {
      this.dropdownOptions.authors = ['Все', ...options]
    },
    next() {
      if (!this.isLastPage) {
        this.infoPaging.currentPage = Number(this.infoPaging.currentPage) + 1
      }
    },
    setOptionForFilter(option: any) {
      this.contentLength = 0

      this.filters = {
        ...this.filters,
        [option.filter.toLowerCase()]: option.title
      }
    },
    updateContent(content: number) {
      this.contentLength += content;
    }
  }
})
