
import {defineComponent, ref, computed} from 'vue';
import { store, useStore } from '@/store';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import Parameters from '../modal-blocks/Parameters.vue'
import articles from "@/services/articles";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import BaseError from '@/components/ui/BaseError.vue';
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import AddVehicleModal from '@/components/modals/AddVehicleModal.vue';
import Spinner from '@/components/Spinner.vue';
import {roles} from "@/services/roles/serviceRoles";
import crossApplicab from "@/services/crossApplicab";
import cross from "@/services/cross";
import DropdownActions from '../history/DropdownActions.vue';
import vehicles from '@/services/vehicles';
import AddMotorCodeModal from '@/components/modals/AddMotorCodeModal.vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { IDataFile } from '@/models/PricesModel';
import { ConvertDate } from '@/components/converters/date';

export default defineComponent({
  name: 'UnconfirmedWrapper',
  components: {
    BaseTable,
    Spinner,
    AddVehicleModal,
    ModalWrapper,
    Parameters,
    BaseIcon,
    BaseCheckbox,
    BaseError,
    DropdownActions
},
  data: () => ({
    isLoading: false as boolean,
    errors: [] as Array<any>,
    menu: [
      {key: 'unconf', value: 'Только новые'},
      {key: 'conf', value: 'Есть в подтвержденных'},
    ] as object,
    isDropdownRevealed: false as boolean,
    vehicleFilter: 'unconf' as string,
    vehicleAdd: null as any,
  }),
  props: {
    content: {
      type: Object,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    isVehicleNotAdded() {
      return (vehicle: any) => {
        const isVehicleFound = this.errors.findIndex(({error, id}) => {
          return error === 'vehicle-not-found' && vehicle.carId === id
        })

        return isVehicleFound >= 0
      }
    },
    headers() {
      return [
        {
          title: 'Марка',
          content: (item: any) => item.manufacturer
        },
        {
          title: 'Модель авто',
          content: (item: any) => item.model
        },
        // {
        //   title: 'V двигателя (ccm)',
        //   content: (item: any) => item.cylinderCapacityCcm
        // },
        {
          title: 'Мощность',
          content: (item: any) => item.powerKwFrom && item.powerHpFrom ? `${item.powerKwFrom}kW / ${item.powerHpFrom}hp` : null,
        },
        {
          title: 'Выпуск',
          content: (item: any) => {
            const fullDate = String(item.yearOfConstructionFrom)

            return fullDate
              ? `${fullDate.slice(0, -2)}.${fullDate.slice(-2, fullDate.length)}`
              : ''
          }
        },
        {
          title: 'Тип кузова',
          content: (item: any) => item.constructionType
        },
        {
          title: 'Код двигателя',
          content: (item: any) => item.motorCodes || '-'
        },
        {
          title: '',
          content: (item: any) => ''
        },
      ]
    },
    isAddVehicleInConfirmedVehicles() {
      return (carId: number) => {

        const indexConfirmedVehicle = this.content.confirmed.findIndex((confirmedVehicle: any) => {
          return confirmedVehicle.tecdocVehicleId === carId
        })

        return indexConfirmedVehicle >= 0
      }
    },
    convertErrors() {
      return (id: any, article: any) => {
        let result = [] as any;
        this.errors.filter(e => e.brandId === id && e.brandArt === article).forEach((o: any) => {
          if (o.error === 'cross_exist') {
            let text = `Кросс <span style="color: #4F5058;">${o.brand} ${o.brandArt}</span> уже добавлен
            ${o.articleId === this.article.articleId ? 'в этот артикул' : ' в артикул <span style="color: #4F5058;">' + o.article + '</span>'}`;
            result.push(text)
          } else if (o.error === 'brand_not_found') {
            let text = `Бренд <span style="color: #4F5058;">${o.brand} (${o.brandArt})</span> не найден`;
            result.push(text)
          }
        });
        return result
      }
    },
    clearName() {
      return (str: string) => {
        return str.toUpperCase().replace(/-/g, '').replace(/ /g, '')
      }
    },
    getApplicabilityBody() {
      return (res: any) => {
        let result = [] as any;
        if (this.getUnconfirmedVehicles.length > 99) {
          this.moreThan99 = true
        }
        if (this.getUnconfirmedVehicles.length && (this.pageable.page + 1 >= Math.ceil(this.getUnconfirmedVehicles.length / 100))) {
          this.pageable.page = Math.ceil(this.getUnconfirmedVehicles.length / 100) - 1;
        } else if (!this.getUnconfirmedVehicles.length){
          this.pageable.page = 0;
        }
        return this.getUnconfirmedVehicles.slice((this.pageable.page) * 100, (this.pageable.page) * 100 + 100);
      }
    },
    getAddedApplicabilityBody() {
      return (res: any) => {
        if (this.getAddedUnconfirmedVehicles.length > 99) {
          this.moreThan99 = true
        }
        if (this.getAddedUnconfirmedVehicles.length && (this.pageable.page + 1 >= Math.ceil(this.getAddedUnconfirmedVehicles.length / 100))) {
          this.pageable.page = Math.ceil(this.getAddedUnconfirmedVehicles.length / 100) - 1;
        } else if (!this.getAddedUnconfirmedVehicles.length) {
          this.pageable.page = 0;
        }
        return this.getAddedUnconfirmedVehicles.slice((this.pageable.page) * 100, (this.pageable.page) * 100 + 100);
      }
    },
    creationDate() {
      return (date: any) => {
        if (String(date).length === 6) {
          return String(date)[4] + String(date)[5] + '.' + String(date)[0] + String(date)[1] + String(date)[2] + String(date)[3]
        }
      }
    },
    getParameters() {
      return (item: any) => {
        return item.attributes.map((o: any) => {
          return `${o.attrName} ${o.attrValue}`
        }).join(', ')
      }
    },
    getUserRole(): any {
      return this.$store.getters.getUserRole
    },
    userCanAddDuplicateCross(): any {
      return this.$store.getters.getAllPermissions.add_duplicate_cross[this.$store.getters.getUserRole]
    },
  },
  methods: {
    async addMotorCodeForVehicle(carId: any) {
      const foundedConfirmedVehicle = this.content.confirmed.filter((confirmedVehicle: any) => {
        return confirmedVehicle.tecdocVehicleId === carId
      })

      const foundedVehicle = await vehicles.findById(foundedConfirmedVehicle[0].vehicleId)

      const foundedSelectedVehicleVehicle = this.content.unconfirmed.find((unconfirmedVehicle: any) => {
        return unconfirmedVehicle.id === carId
      })

      const { motorCodes } = foundedSelectedVehicleVehicle

      try {
        await vehicles.updateVehicle(foundedVehicle.id, { motorCode: motorCodes, modelId: foundedVehicle.model.id })
        this.$emit('closeModal')
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
    openAddVehicleModal(vehicle: any) {
      let element = document.getElementById(`modal-vehicle-add`)!
      element.style.display = 'flex'

      this.vehicleAdd = this.content.unconfirmed.filter((veh: any) => veh.carId === vehicle.carId)
    },
    closeAddVehicleModal() {
      let element = document.getElementById(`modal-vehicle-add`)!
      element.style.display = 'none'
    },
    approveAllCross() {
      this.content.unconfirmed.map((item: any) => {
        const confirmedContent = this.content.confirmed.map((c: any) => this.clearName(c.tecdocBrandId + '|' + c.brandArt))
        const crossAlreadyAdded = confirmedContent.some((a: any) => a.includes(this.clearName(item.brandId + '|' + item.oeArticle)))
        
        if (!crossAlreadyAdded) {
          this.approveCross(item)
        }
      })
    },
    approveCross(item: any) {
      let payload = {
        brandId: item.brandId,
        brand: item.brand,
        brandArt: item.oeArticle,
        direction: 'OE'
      };
      cross.approveCross(this.article.articleId, payload).then(async _ => {
        let payload = {key: 'CROSS', value: await cross.getAllCrosses(this.article.articleId)};
        this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
        this.$store.commit(CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE, {
          key: 'CROSS',
          value: item
        });
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(error => {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        if (error.data) {
          error.data.brand = payload.brand;
          error.data.brandArt = payload.brandArt;
          error.data.brandId = payload.brandId;
          this.errors.push(error.data);
        }
        throw error
      })
    },
    approveDuplicateCrossToDB(item: any) {
      let payload = {
        brandId: item.brandId,
        brand: item.brand,
        brandArt: item.oeArticle,
        direction: 'OE'
      };
      cross.approveDuplicateCrossToDB(this.article.articleId, payload).then(async _ => {
        let payload = {key: 'CROSS', value: await cross.getAllCrosses(this.article.articleId)};
        this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
        this.$store.commit(CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE, {
          key: 'CROSS',
          value: item
        });
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(error => {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        if (error.data) {
          error.data.brand = payload.brand;
          error.data.brandArt = payload.brandArt;
          error.data.brandId = payload.brandId;

          const isErrorExist = this.errors.findIndex((currentError: any) => {
            return currentError.brandId === payload.brandId && error.data.error === currentError.error
          }) >= 0

          if (!isErrorExist) {
            this.errors.push(error.data);
          }
        }

        throw error
      })
    },
    approveApplicability() {
      this.isLoading = true
      if (!!this.checked.length) {
        crossApplicab.approveVehicles(this.article.articleId, this.checked).then(async _ => {
          const newApplicability = await crossApplicab.getApplicability(this.article.articleId)

          this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {
            key: 'APPLICABILITY',
            value: newApplicability
          });

          console.log('newApplicability', newApplicability)

          if (_.crosses && _.crosses.length) {
            this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
            this.$store.commit(CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE, {
              key: 'APPLICABILITY',
              value: newApplicability.map((cross: any) => cross.tecdocVehicleId)
            });
          }

          this.checked = [];
          this.checkedAll = false;
          this.isLoading = false

          if (_.vehiclesNotFounded && _.vehiclesNotFounded.length) {
            this.errors = this.errors.concat(_.vehiclesNotFounded.map((vehicleId: any) => {
              return {
                error: 'vehicle-not-found',
                id: vehicleId
              }
            }))
            this.isLoading = false
          }
        }).catch(error => {
          this.isLoading = false
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
          throw error
        })
      }
    },
    setCheckedAll() {
      this.checkedAll = !this.checkedAll;

      if (this.checkedAll) {
        this.checked = [];
        this.checked = this.content.unconfirmed.map((vehicle: any) => vehicle.carId)
      } else {
        this.checked = []
      }
    },
    setChecked(item: any) {
      let index = this.checked.findIndex((o: string) => o === item);

      if (index !== -1) {
        this.checked.splice(index, 1);
        this.checkedAll = false
      } else {
        this.checked.push(item);
        if (this.content.unconfirmed.length === this.checked.length) {
          this.checkedAll = true
        }
      }
    },
    previous() {
      if (this.pageable.page) {
        this.pageable.page -= 1
      }
    },
    next() {
      if (this.vehicleFilter === 'unconf' && this.pageable.page + 1 !== Math.ceil(this.getUnconfirmedVehicles.length / 100)) {
        this.pageable.page += 1
      } else if (this.vehicleFilter === 'conf' && this.pageable.page + 1 !== Math.ceil(this.getAddedUnconfirmedVehicles.length / 100)) {
        this.pageable.page += 1
      }
    }
  },
  setup(props) {
    const store = useStore();
    const tableHeader = ['', 'Марка', 'Модель авто', 'V двигателя (ccm)', 'Мощность', 'Выпуск', 'Тип кузова', 'Код двигателя'];
    const entityModal = ref(null as any);
    const checked: { [x: string]: any } = ref([]);
    const unconfirmedVehicles: any = ref([]);
    const checkedAll = ref(false as boolean);
    const moreThan99 = ref(false);
    const pageable: { [x: string]: any } = computed(() => store.getters.getVehiclePageable);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));

    const article: { [x: string]: any } = computed(() => store.getters.getArticleCard);
    const getValue = computed(() => {
      return (item: any) => {
        let result = `${item.formattedValue}`;
        if (item.criteriaDescription) {
          let regex = /\[(.*?)\]/;
          let matched = regex.exec(item.criteriaDescription);
          if (matched) {
            result = result + ' ' + `${matched[1]}`
          }
        }
        return result
      }
    });

    const getUnconfirmedVehicles = computed(() => {
      return props.content.unconfirmed
        .filter((e: any) => !props.content.confirmed
        .some((c: any) => c.tecdocVehicleId === e.carId))
        .map((o: any) => {
          return [
            o.errors ? 'error' : o.carId, // Checkbox подтверждения
            o.manufacturer, // Марка
            o.model, // Модель авто
            // o.carDesc.split(' ')[0], // V двигателя
            o.cylinderCapacity, // V двигателя (ccm)
            o.powerKwFrom && o.powerHpFrom ? `${o.powerKwFrom}kW / ${o.powerHpFrom}hp` : null, // Мощность
            creationDate(o.yearOfConstructionFrom), // Выпуск
            o.constructionType, // Тип кузова
            o.motorCodes || '-', //Код двигателя
            '',
            // o.carDesc.split(' ')[1], // Модификация
            // o.modBodyNum,
            // !!o.attributes.length ? getParameters(o) : null // Параметры
          ]
        })
    });

    const getAddedUnconfirmedVehicles = computed(() => {
      // props.content.unconfirmed
      //   .filter((e: any) => props.content.confirmed.some((c: any) => c.tecdocVehicleId === e.carId))
      //   .map((o: any) => {

      return props.content.unconfirmed.map((o: any) => {
        return [
          o.errors ? 'error' : o.carId, // Checkbox подтверждения
          o.manufacturer, // Марка
          o.model, // Модель авто
          // o.carDesc.split(' ')[0], // V двигателя
          o.cylinderCapacity, // V двигателя (ccm)
          o.powerKwFrom && o.powerHpFrom ? `${o.powerKwFrom}kW / ${o.powerHpFrom}hp` : null, // Мощность
          creationDate(o.yearOfConstructionFrom), // Выпуск
          o.constructionType, // Тип кузова
          o.motorCodes || '-', //Код двигателя
          '', //оставляем пустой элемент, чтобы корректно отображалась надпись Авто уже добавлено
          // o.carDesc.split(' ')[1], // Модификация
          // o.modBodyNum,
          // !!o.attributes.length ? getParameters(o) : null // Параметры
        ]
      })
    });

    function openModal(item: any) {
      entityModal.value = item;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeModal() {
      entityModal.value = null;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function getParameters(item: any) {
      return item.attributes.map((o: any) => {
        return `${o.attrName} ${o.attrValue}`
      }).join(', ')
    }

    function creationDate(date: any) {
      if (String(date).length === 6) {
        return String(date)[4] + String(date)[5] + '.' + String(date)[0] + String(date)[1] + String(date)[2] + String(date)[3]
      }
    }

    return {
      article,
      tableHeader,
      checked,
      checkedAll,
      entityModal,
      getRandomId,
      getValue,
      moreThan99,
      pageable,
      getUnconfirmedVehicles,
      getAddedUnconfirmedVehicles,
      openModal,
      closeModal,
      // setChecked,
      // setCheckedAll,
      unconfirmedVehicles,
    }
  }
})
