
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import crossApplicab from '@/services/crossApplicab';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import { store } from '@/store';

export default defineComponent({
  name: 'CompletenessModal',
  components: { ModalWrapper },
  data: () => ({
    completenessNumber: '' as any
  }),
  props: {
    article: {
      type: Object,
      default: () => null
    },
    cross: {
      type: Object,
      default: () => null
    }
  },
  computed: {
  },
  methods: {
    setCompletenessNumber(value: any) {
      this.completenessNumber = value
    },
    async updateCompleteness() {
      try {
        const response = await crossApplicab.setCompleteness(this.cross.id, {
          articleId: this.$route.params.id,
          crossId: this.cross.id,
          completeness: this.completenessNumber
        })
        this.$emit('closeModal')
        this.$emit('replaceCrossByNew', response)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch(e) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    }
  },
  async mounted() {

  }
})
