
import {defineComponent} from 'vue';
import CopyIcon from '@/components/icons/CopyIcon.vue';

export default defineComponent({
  name: 'AnalogItemConfirmed',
  emits: [],
  components: {
    CopyIcon
  },
  props: {
    item: {
      type: Object,
      default: () => null
    },
    article: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    currentCross: null as any
  }),
  methods: {
    copyText() {
      const analogArticleRef = this.$refs.analogArticleRef as any;
      navigator.clipboard.writeText(analogArticleRef.innerHTML)
    }, 
  }
})
