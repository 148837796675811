import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "history-page__change-card"
}
const _hoisted_2 = { class: "history-page__date-column" }
const _hoisted_3 = { class: "history-page__date" }
const _hoisted_4 = { class: "history-page__history-list" }
const _hoisted_5 = {
  key: 0,
  class: "history-page__history-item"
}
const _hoisted_6 = { class: "history-page__history-item-time-wrapper" }
const _hoisted_7 = { class: "history-page__history-item-time" }
const _hoisted_8 = { class: "history-page__history-info" }
const _hoisted_9 = { class: "history-page__history-item-author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataChanges, (change) => {
    return (_openBlock(), _createBlock(_Fragment, null, [
      (_ctx.getTimeChanges(change))
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            (_ctx.getTimeChanges(change))
              ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                  _createVNode("div", _hoisted_2, [
                    _createVNode("div", _hoisted_3, _toDisplayString(change), 1)
                  ]),
                  _createVNode("div", _hoisted_4, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.getTimeChanges(change), (changesOverTime) => {
                      return (_openBlock(), _createBlock(_Fragment, null, [
                        (changesOverTime && changesOverTime.desc)
                          ? (_openBlock(), _createBlock("div", _hoisted_5, [
                              _createVNode("div", _hoisted_6, [
                                _createVNode("div", _hoisted_7, _toDisplayString(_ctx.getInfoTime(changesOverTime.date)), 1)
                              ]),
                              _createVNode("div", _hoisted_8, [
                                _createVNode("div", {
                                  class: "history-page__history-item-action",
                                  innerHTML: _ctx.getInfoAction(changesOverTime)
                                }, null, 8, ["innerHTML"]),
                                _createVNode("div", _hoisted_9, " Автор: " + _toDisplayString(changesOverTime.username), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 64))
  }), 256))
}