
import { defineComponent } from "vue";

export default defineComponent({
  name: "CopyIcon",
  props: {
    activeColor: {
      type: String,
      default: () => "color-action"
    },
  },
  computed: {
    classList(): Array<string> {
      return [
        'copy-icon',
        `copy-icon_color-active_${this.activeColor}`,
      ];
    }
  }
});
