
import { defineComponent } from 'vue';
import Spinner from '@/components/Spinner.vue';
import articles from '@/services/articles';

export default defineComponent({
  name: 'AttributesImages',
  components: {
    Spinner,
  },
  props: {
    marketplaces: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    images: [] as any,
    pickedImages: [] as any,
    isLoading: false as boolean,
    isUploadingImage: false as boolean,
    selectedMarketplaceForImages: null as any
  }),
  computed: {
    checkImage(): any {
      return (image: any) => {
        const idx = this.pickedImages.findIndex((pickedImage: any) => pickedImage === image)

        return idx !== -1
          ? 'images-with-signed__active-image'
          : ''
      }
    },
    articleName(): any {
      return this.$store.getters.getArticleCard.article
    },
    articleId(): any {
      return this.$store.getters.getArticleCard.articleId
    },
  },
  methods: {
    async deleteImages() {
      this.isLoading = true
      await Promise.all(this.pickedImages.map(async (pickImage: any) => {
        const arr = pickImage.split('/')
        const imageName = arr[arr.length - 1]

        await articles.deleteAttributeImage(this.articleId, imageName)
        this.images = this.images.filter((image: any) => image !== pickImage)
      }));
      this.isLoading = false
      this.pickedImages = []
    },
    async uploadFiles(e: any) {
      const files = e.target.files || e.dataTransfer.files;
      this.isUploadingImage = true

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('articleId', String(this.$route.params.id));
        formData.append('extension', files[i].type.split('/').pop());
        formData.append('file', files[i]);
      }

      const newImage = await articles.addAttributeImage(formData)

      this.images = [
        ...this.images,
        newImage
      ]

      this.isUploadingImage = false
    },
    pickFiles() {
      (document.getElementById(`input-file`) as HTMLInputElement).click()
    },
    pickImage(image: any) {
      const isImageSelected = this.pickedImages.findIndex((pickedImage: any) => pickedImage === image) !== -1

      if (isImageSelected) {
        this.pickedImages = this.pickedImages.filter((pickedImage: any) => pickedImage !== image)
        return;
      }
      this.pickedImages = [
        ...this.pickedImages,
        image
      ]
    },
  },
  async mounted() {
    this.isLoading = true
    this.images = await articles.getAttributeImages(this.articleName)
    this.isLoading = false
  }
});
