
import {defineComponent, ref, computed} from 'vue';
import Vehicles from '@/components/article-card/modal-blocks/Vehicles.vue';

export default defineComponent({
  name: 'AutoDirectoryWrapper',
  components: {
    Vehicles
  },
  props: {
    confirmed: {
      type: Object,
      default: () => null
    }
  },
})
