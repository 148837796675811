
import { defineComponent } from 'vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";

export default defineComponent({
  name: 'CommodityGroupFieldItem',
  data: () => ({
  }),
  props: {
    inputValue: {
      type: String
    },
    field: {
      type: String
    }
  },
  computed: {
    getCurrentFieldName(): any {
      switch (this.field) {
        case 'desc':
          return `Описание`
        case 'description':
          return `Описание`
        case 'descTecDoc':
          return `Текдок описание`
        case 'desigDesc':
          return `Описание сборки`
        case 'name':
          return `Название`
        case 'units':
          return `Единица измерения`
        case 'descShort':
          return `Краткое описание`
        case 'motor':
          return `Код двигателя`
        default:
          return this.field
      }
    }
  },
  methods: {
    changeFieldValue(value: any) {
      this.$emit('changeValue', this.field, value)
    },
  },
})
