
import { defineComponent } from 'vue';
import articles from '@/services/articles';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'AttributesView',
  components: {
  },
  data: () => ({
    isEdit: false as boolean,
    info: {
      appearance: null,
      label: null,
      material: null,
      pack: null
    } as any,
    defaultData: null as any
  }),
  methods: {
    cancelChanges() {
      this.isEdit = false
      this.info = { ...this.defaultData }
    },
    changeData(event: any, fieldName: any) {
      this.info[fieldName] = event.target.value
    },
    changeEditable() {
      this.isEdit = true
    },
    async save() {
      const { appearance, label, material, pack } = this.info

      const payload = {
        appearance,
        articleId: this.article.articleId,
        label,
        material,
        pack
      }

      try {
        this.defaultData = await articles.updateAttribute(this.article.articleId, payload)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        console.error(error)
      } finally {
        this.isEdit = false
      }
    }
  },
  computed: {
    articleName(): string {
      return this.article.article
    },
    article(): any {
      return this.$store.getters.getArticleCard as any
    },
  },
  async mounted() {
    this.defaultData = await articles.getAttributesById(this.article.articleId)
    this.info = { ...this.defaultData }
  }
});
