
  import {computed, defineComponent, onMounted, ref, watch} from 'vue';
  import {Category, Status, Type} from "@/components/types/enums";
  import articles from "@/services/articles";
  import ozon from "@/services/ozon";
  import BaseInput from "@/components/ui/BaseInput.vue";
  import BaseIcon from "@/components/ui/BaseIcon.vue";

  export default defineComponent({
    name: 'MassSelect',
    emits: ['update', 'clearSelect', 'clearAttr'],
    components: {
      BaseInput,
      BaseIcon
    },
    props: {
      validationBtn: {
        type: Object,
        default: null
      },
      defaultPickedItems: {
        type: Array,
        required: false,
        default: [],
      },
      isInput: {
        type: Boolean,
        default: () => false
      },
      item: {
        type: String,
        default: () => ''
      },
      type: {
        type: String,
        default: () => ''
      },
      disabled: {
        type: Boolean,
        default: () => false
      },
      options: {
        type: Array,
        default: () => []
      },
    },
    setup(props, {emit}) {
      const selectOptions = ref({status: [], category: [], codetype: [], ozon_category: [], ozon_category_parameter: [], confirmed_articles_parameter: []} as any);
      const editedItem = ref({} as any);
      const categories = ref([] as any);
      const getName = computed(() => {
        return (item: any) => {
          if (props.type === 'OZON_CATEGORY') {
            return item.title
          } else if (props.type === 'OZON_CATEGORY_PARAMETER') {
            return item.value
          } else if (props.type === 'CONFIRMED_ARTICLE_PARAMETER') {
            return item.name + ' - ' + item.value
          } else {
            return item && !!Object.values(item).length ? Object.values(item)[0] : ''
          }}
      });

      const isApprovedByValidationScheme = computed(() => {
        if (!props.validationBtn) return false

        return inputValue.value.length < props.validationBtn.min ||
          inputValue.value.length > props.validationBtn.max
      })

      const currentOptions = computed(() => {
        return selectOptions.value[props.type.toLowerCase()]
      });
      const pickedItems = ref([] as any)
      const inputValue = ref('')

      watch(() => currentOptions.value, (val) => {
        editedItem.value = val.find((o: any) => Object.keys(o)[0] === props.item)
      });

      watch(() => props.item, async (val) => {
        if (val) await setOptions()
      });

      function clearSelect() {
        editedItem.value = {}
        emit('clearSelect')
      }

      function clickOption() {
        if (!pickedItems.value.filter((item: any) => item.id === editedItem.value.id).length) {
          if (props.type === 'OZON_CATEGORY' ||
              props.type === 'OZON_CATEGORY_PARAMETER' ||
              props.type === 'CONFIRMED_ARTICLE_PARAMETER'
          ) {
            emit('update', editedItem.value)
            pickedItems.value.push(editedItem.value)

          } else {
            emit('update', Object.keys(editedItem.value)[0])
          }
        }
      }

      function deletePickedItems(deleteItem: any) {
        if (deleteItem.id) {
          pickedItems.value = pickedItems.value.filter((item: any) => item.id !== deleteItem.id)
          if (editedItem.value.id === deleteItem.id) {
            editedItem.value = {}
          }
          emit('clearAttr', deleteItem)
        } else {
          pickedItems.value = pickedItems.value.filter((item: any) => item !== deleteItem)
          emit('clearAttr', deleteItem)
        }
      }

      async function getStatuses() {
        try {
          selectOptions.value.status = [];
          let status: { [x: string]: any } = Status;
          let result = await articles.getNextStatus(props.item);
          Object.keys(status).forEach(o => {
            if (result.includes(o)) selectOptions.value.status.push({[o]: status[o]})
          })
        } catch (error) {
          throw error
        }
      }

      async function setOptions() {
        let category: { [x: string]: string } = Category;
        let type: { [x: string]: string } = Type;
        let status: { [x: string]: string } = Status;
        selectOptions.value.category = Object.keys(category).map(key => {
          return {[key]: category[key]}
        });
        selectOptions.value.codetype = Object.keys(type).map(key => {
          return {[key]: type[key]}
        });
        if (props.type === 'STATUS' && props.item && props.item !== 'NEW_TEMP_STATUS') {
          // Карточка артикула
          await getStatuses();
          editedItem.value = {[props.item]: status[props.item]};
          selectOptions.value.status.push(editedItem.value);
        } else if (props.type === 'STATUS' && props.item === 'NEW_TEMP_STATUS') {
          // Лист освоения
          editedItem.value = {['TEMPORARY']: status['TEMPORARY']};
        } else if (props.type === 'STATUS') {
          // Все артикулы (поиск)
          selectOptions.value.status = Object.keys(status).map(key => {
            return {[key]: status[key]}
          })
        } else if (props.type === 'OZON_CATEGORY') {
          let categories = await ozon.getOzonCategory();
          selectOptions.value.ozon_category = categories
        } else if (props.type === 'OZON_CATEGORY_PARAMETER') {
          selectOptions.value.ozon_category_parameter = props.options
        } else if (props.type === 'CONFIRMED_ARTICLE_PARAMETER') {
          selectOptions.value.confirmed_article_parameter = props.options
        }
      }


      function addToSelect(name: any) {
        if (
            !pickedItems.value.filter((item: any) => item === name).length &&
            inputValue.value.trim().length
        ) {
          if (props.type === 'OZON_CATEGORY_PARAMETER' || props.isInput) {
            emit('update', name)
            pickedItems.value.push(name)
            inputValue.value = ''
          }
        }
      }

      onMounted(async () => {
        pickedItems.value = props.defaultPickedItems
        await setOptions()
      });

      return {isApprovedByValidationScheme, inputValue, addToSelect, deletePickedItems, editedItem, currentOptions, getName, clickOption, clearSelect, pickedItems}
    }
  })
