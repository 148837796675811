
import { defineComponent, ref, computed, nextTick } from 'vue';
import {useStore} from '@/store'
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchEntities from '@/components/search/SearchEntities.vue'
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import tree from "@/services/tree";
import articles from '@/services/articles';

export default defineComponent({
  name: 'AddTreeElement',
  emits: ['close-modal'],
  components: {
    ModalWrapper,
    SearchEntities
  },
  props: {
    parentId: {
      type: Number,
      default: () => 0
    },
    treeId: {
      type: Number,
      default: () => 0
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const mainArticle = computed(() => store.getters.getArticleCard);
    const article = ref({} as any);
    const description = ref('' as string);
    const countParts = ref(0 as number);
    const parameter = ref('INCLUDED_IN' as string);
    const isOpenModal = ref(false as boolean)

    function setDefaultData() {
      article.value = {};
      description.value = '';
      countParts.value = 0;
      parameter.value = 'INCLUDED_IN'
    }

    function openSearchModal(event?: any) {
      if (!isOpenModal.value) {
        if (event) event.blur()

        const element = document.getElementById(`modal-${getRandomId.value}`)!;
        element.style.display = 'flex'
      }
      isOpenModal.value = true
    }

    function closeSearchModal() {
      const element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
      isOpenModal.value = false
    }

    function updateEntity(entity: any) {
      article.value = entity;
      closeSearchModal()
    }

    async function addSuitableTree() {
      const prevCloneIds = mainArticle.value.clones.map((clone: any) => clone.articleId)
      await articles.updateClone(
        {
          articleId: mainArticle.value.articleId,
          cloneIds: [
            ...prevCloneIds,
            article.value.articleId
          ]
        }
      )

      store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {
        key: 'TREE',
        isSuitableTree: true,
        value: [
          ...mainArticle.value.clones,
          { articleId: article.value.articleId, name: article.value.article }
        ]
      })

      setDefaultData();
      emit('close-modal')
    }

    async function addToTree() {
      let payload = [{
        articleId: article.value.articleId,
        parentId: props.parentId,
        description: description.value,
        countParts: Number(countParts.value),
      }];
      try {
        const response = await tree.addToTree(String(props.treeId), payload)
        store.commit(
          CardArticleMutationTypes.UPDATE_PARAMETER_VALUE,
          { key: 'TREE', value: response }
        )
        setDefaultData()
        emit('close-modal')
      } catch (error) {
        article.value = {}
        closeSearchModal()
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      }
    }

    async function createTree() {
      let payload = [];
      payload.push({
        articleId: mainArticle.value.articleId,
        parentId: parameter.value === 'INCLUDED_IN' ? article.value.articleId : null,
        description: '',
        countParts: 0
      });
      payload.push({
        articleId: article.value.articleId,
        parentId: parameter.value === 'INCLUDED_IN' ? null : mainArticle.value.articleId,
        description: description.value,
        countParts: Number(countParts.value)
      });
      try {
        const response = await tree.createTree(payload)
        store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {key: 'TREE', value: response});

        if (parameter.value === 'INCLUDED_IN') {
          await addSuitableTree()
        }

        setDefaultData();
        emit('close-modal')
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      }

      // tree.createTree(payload).then(response => {
      //   store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, {key: 'TREE', value: response});
      //   setDefaultData();
      //   emit('close-modal')
      // }).catch(error => {
      //   store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      //   throw error
      // })
    }

    return {
      getRandomId,
      mainArticle,
      article,
      description,
      countParts,
      parameter,
      openSearchModal,
      updateEntity,
      addToTree,
      createTree,
      isOpenModal,
      addSuitableTree
    }
  }
})
