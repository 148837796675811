
import {defineComponent, ref, watch, computed, onMounted} from 'vue';
import {useStore} from '@/store'
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchEntities from '@/components/search/SearchEntities.vue'
import articles from "@/services/articles";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import analogController from "@/services/analog"

export default defineComponent({
  name: 'Analog',
  emits: ['close-modal'],
  components: {
    ModalWrapper,
    SearchEntities
  },
  props: {
    content: {
      type: Object,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const analog = ref({} as any);
    const comment = ref({main: '', analog: ''} as any);
    const searchText = ref('' as string);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const article = computed(() => store.getters.getArticleCard);
    const params = computed(() => store.getters.getParamsCard);
    const groupId = computed(() => {
      let parameter = params.value.find((o: any) => o.key === 'ANALOG');
      let found = parameter.confirmed.find((o: any) => o.articleId === article.value.articleId);
      if (found) {
        comment.value.main = found ? found.articleComment : '';
        return found.groupId
      } else {
        return null
      }
    });

    watch(() => props.content, (val) => {
      if (val) {
        analog.value = {...val.article};
        comment.value.analog = val.articleComment
      } else {
        setDefaultData()
      }
    });

    function setDefaultData() {
      let parameter = params.value.find((o: any) => o.key === 'ANALOG');
      let found = parameter.confirmed.find((o: any) => o.articleId === article.value.articleId);
      if (found) {
        comment.value.main = found ? found.articleComment : '';
      }
      comment.value.analog = '';
      analog.value = {};
    }

    function openSearchModal() {
      searchText.value = analog.value.article;
      const element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function updateEntity(entity: any) {
      analog.value = entity;
      const element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function updateAnalog() {
      let parameter = params.value.find((o: any) => o.key === 'ANALOG');
      let rootId = parameter.confirmed.find((o: any) => o.articleId === article.value.articleId).id;
      let payload = {
        rootId: rootId,
        analogId: props.content.id,
        rootComment: comment.value.main,
        analogComment: comment.value.analog
      };
      analogController.updateAnalog(payload).then(response => {
        let payload = {key: 'ANALOG', value: response};
        store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, payload);
        setDefaultData();
        emit('close-modal')
      })
    }

    function createAnalog() {
      let payload = {groupId: groupId.value, analogs: [] as any};
      // Если уже есть аналоги, тогда указывать оновной артикул не нужно
      if (!groupId.value) {
        payload.analogs.push({articleId: article.value.articleId, articleComment: comment.value.main})
      }
      payload.analogs.push({articleId: analog.value.articleId, articleComment: comment.value.analog});
      analogController.createAnalog(payload).then((response: any) => {
        let found_main = response.find((o: any) => o.articleId === article.value.articleId);
        let found_analog = response.find((o: any) => o.articleId === analog.value.articleId);
        if (found_main) found_main.article = article.value;
        if (found_analog) found_analog.article = analog.value;
        let payload = {key: 'ANALOG', value: response};
        store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
        setDefaultData();
        emit('close-modal')
      }).catch((error: any) => {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      })
    }

    function deleteAnalog() {
      let parameter = params.value.find((o: any) => o.key === 'ANALOG');
      if (parameter.confirmed.length === 2) {
        let rootId = parameter.confirmed.find((o: any) => o.articleId === article.value.articleId).id;
        Promise.all([
          analogController.deleteAnalog(String(rootId)),
          analogController.deleteAnalog(String(props.content.id))
        ]).then(_ => {
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'ANALOG', value: rootId});
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'ANALOG', value: props.content.id});
          setDefaultData();
          emit('close-modal')
        })
      } else {
        analogController.deleteAnalog(String(props.content.id)).then(_ => {
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {key: 'ANALOG', value: props.content.id});
          setDefaultData();
          emit('close-modal')
        })
      }
    }

    onMounted(() => {
      setDefaultData()
    });

    return {
      searchText,
      article,
      analog,
      groupId,
      comment,
      getRandomId,
      openSearchModal,
      updateEntity,
      updateAnalog,
      createAnalog,
      deleteAnalog
    }
  }
})
