<template>
  <div class="ozon-link-wrapper">
    ссылка на ОЗОН<br>
    {{link}}
<!--    <a :href="link" target="_blank">-->
<!--      <div>-->
<!--        Купить на-->
<!--      </div>-->
<!--      <img :src="require('@/assets/images/ozon_logo.svg')" alt="">-->
<!--    </a>-->
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OzonLinkWrapper',
  props: {
    link: {
      type: String,
      required: true,
      default: () => ''
    },
  }
})
</script>

<style lang="scss" scoped>

.ozon-link-wrapper {

  a {
    padding: 10px;
    display: inline-flex;
  }

  a:hover {
    background-color: $color-light-green;
  }

  img {
    margin: 0 10px;
    width: 100px;
  }
}

</style>
