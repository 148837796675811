
import { computed, defineComponent } from 'vue';
import DropdownActions from '@/components/article-card/history/DropdownActions.vue';
import { ConvertDate } from '@/components/converters/date';

const CLEAR_VALUE_FILTER = 'Очистить выбор'
const DEFAULT_VALUE_FILTER = 'Выберите значение'

export default defineComponent({
  name: 'ContentWithFiltersWrapper',
  components: {
    DropdownActions
  },
  props: {
    settingFilters: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: () => 'CONFIRMED'
    },
    content: {
      type: Object,
      required: true,
    },
    showFilters: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    duplicateContent: null as any,
    filtersApplicability: {} as any,
  }),
  methods: {
    creationDate(date: any) {
      if (String(date).length === 6) {
        return String(date)[4] + String(date)[5] + '.' + String(date)[0] + String(date)[1] + String(date)[2] + String(date)[3]
      }
    },
    clearFilters(exceptions?: Array<string>) {
      const filters = Object.keys(this.filtersApplicability)

      filters.map((filterBy: string) => {
        if (!(exceptions && exceptions.includes(filterBy))) {
          this.filtersApplicability[filterBy] = null
        }
      })
    },
    setOptionFilter(option: any, field: string) {
      if (this.filtersApplicability[field] === option.title) {
        return
      }

      if (option.title === CLEAR_VALUE_FILTER) {
        if (this.type === 'CONFIRMED') {
          if (field === 'brand') {
            this.clearFilters()
          }

          if (field === 'model') {
            this.clearFilters(['brand'])
          }

          this.filtersApplicability[field] = null
        }

        if (this.type === 'UNCONFIRMED') {
          switch (field) {
            case 'manufacturer':
              this.clearFilters(['show'])
              break;
            case 'model':
              this.clearFilters(['show', 'manufacturer'])
              break;
          }

          this.filtersApplicability[field] = null
        }
      } else {
        if (this.type === 'CONFIRMED') {
          if (field === 'brand') {
            this.clearFilters(['brand'])
          }

          if (field === 'model') {
            this.clearFilters(['brand', 'model'])
          }

          this.content.confirmed = JSON.parse(JSON.stringify(this.getParamApplicability.duplicateConfirmed))
          this.filtersApplicability[field] = option.title
        }

        if (this.type === 'UNCONFIRMED') {
          if (field === 'manufacturer') {
            this.clearFilters(['show', 'manufacturer'])
          }

          if (field === 'model') {
            this.clearFilters(['show', 'manufacturer', 'model'])
          }

          if (field === 'show') {
            this.clearFilters()
          }


          this.content.unconfirmed = JSON.parse(JSON.stringify(this.getParamApplicability.duplicateUnconfirmed))
          this.filtersApplicability[field] = option.title
        }
      }
    }
  },
  computed: {
    getParamApplicability(): any {
      return this.$store.getters.getParamApplicability
    },
    getOptionsFilter(): any {
      return (field: string): any => {
        if (this.type === 'UNCONFIRMED') {
          return [
            CLEAR_VALUE_FILTER,
            ...new Set(
              this.content.unconfirmed.map((vehicle: any) => {
                if (field === 'kw') return `${vehicle.powerKwFrom}kW / ${vehicle.powerHpFrom}hp`
                if (field === 'yearOfConstructionFrom') return this.creationDate(vehicle.yearOfConstructionFrom)

                return vehicle[field]
              })
            )
          ]
        }

        if (this.type === 'CONFIRMED') {
          if (field === 'brand') {
            return [
              CLEAR_VALUE_FILTER,
              ...new Set(this.getParamApplicability.duplicateConfirmed.map((vehicle: any) => vehicle.brand))
            ]
          }

          if (field === 'model') {
            return [
              CLEAR_VALUE_FILTER,
              ...this.filtersApplicability && this.filtersApplicability.brand
                ? new Set(this.getParamApplicability.duplicateConfirmed.map((vehicle: any) => {
                  if (vehicle.brand === this.filtersApplicability.brand) {
                    return vehicle.model
                  }
                }))
                : new Set(this.getParamApplicability.duplicateConfirmed.map((vehicle: any) => vehicle.model))
            ]
          }

          return [
            CLEAR_VALUE_FILTER,
            ...new Set(
              this.content.confirmed.map((vehicle: any) => {
                if (field === 'kw') return `${vehicle.kw} kW / ${vehicle.hp} PS`

                return vehicle[field]
              })
            )
          ]
        }
      }
    },
    settingsForFilters(): any {
      if (this.type === 'CONFIRMED') {
        return [
          {
            title: 'Марка',
            fieldName: 'brand',
            setOption: (option: any) => this.setOptionFilter(option, 'brand'),
            options: this.getOptionsFilter('brand')
          },
          {
            title: 'Модель авто',
            fieldName: 'model',
            setOption: (option: any) => this.setOptionFilter(option, 'model'),
            options: this.getOptionsFilter('model')
          },
          {
            title: 'V двигателя',
            fieldName: 'volume',
            setOption: (option: any) => this.setOptionFilter(option, 'volume'),
            options: this.getOptionsFilter('volume')
          },
          // {
          //   title: 'Тип топлива',
          //   fieldName: 'fuelType',
          //   setOption: (option: any) => this.setOptionFilter(option, 'fuelType'),
          //   options: this.getOptionsFilter('fuelType')
          // },
          {
            title: 'Мощность',
            fieldName: 'kw',
            setOption: (option: any) => this.setOptionFilter(option, 'kw'),
            options: this.getOptionsFilter('kw')
          },
          {
            title: 'Выпуск',
            fieldName: 'vehYears',
            setOption: (option: any) => this.setOptionFilter(option, 'vehYears'),
            options: this.getOptionsFilter('vehYears')
          },
          {
            title: 'Тип кузова',
            fieldName: 'bodyType',
            setOption: (option: any) => this.setOptionFilter(option, 'bodyType'),
            options: this.getOptionsFilter('bodyType')
          },
          // {
          //   title: 'Модификация',
          //   fieldName: 'modBodyNum',
          //   setOption: (option: any) => this.setOptionFilter(option, 'modBodyNum'),
          //   options: this.getOptionsFilter('modBodyNum')
          // },
        ]
      }

      if (this.type === 'UNCONFIRMED') {
        const currentFilteredContent = [...this.filtersApplicability.show === 'Только новые'
          ? new Set(this.getParamApplicability.duplicateUnconfirmed.filter((e: any) => !this.getParamApplicability.duplicateConfirmed.some((c: any) => c.tecdocVehicleId === e.carId)))
          : new Set(this.getParamApplicability.duplicateUnconfirmed.filter((e: any) => this.getParamApplicability.duplicateConfirmed.some((c: any) => c.tecdocVehicleId === e.carId)))
        ]

        return [
          {
            title: 'Показать',
            fieldName: 'show',
            setOption: (option: any) => this.setOptionFilter(option, 'show'),
            options: [
              'Только новые',
              'Есть в подтвержденных'
            ]
          },
          {
            title: 'Марка',
            fieldName: 'manufacturer',
            setOption: (option: any) => this.setOptionFilter(option, 'manufacturer'),
            options: [
              CLEAR_VALUE_FILTER,
              ...new Set(currentFilteredContent.map((vehicle: any) => vehicle.manufacturer))
            ]
          },
          {
            title: 'Модель авто',
            fieldName: 'model',
            setOption: (option: any) => this.setOptionFilter(option, 'model'),
            options: [
              CLEAR_VALUE_FILTER,
              ...this.filtersApplicability && this.filtersApplicability.manufacturer
                ? new Set(currentFilteredContent.map((vehicle: any) => {
                  if (vehicle.manufacturer === this.filtersApplicability.manufacturer) {
                    return vehicle.model
                  }
                }))
                : new Set(currentFilteredContent.map((vehicle: any) => vehicle.model))
            ]
          },
          {
            title: 'V двигателя',
            fieldName: 'cylinderCapacity',
            setOption: (option: any) => this.setOptionFilter(option, 'cylinderCapacity'),
            options: this.getOptionsFilter('cylinderCapacity')
          },
          {
            title: 'Мощность',
            fieldName: 'kw',
            setOption: (option: any) => this.setOptionFilter(option, 'kw'),
            options: this.getOptionsFilter('kw')
          },
          {
            title: 'Выпуск',
            fieldName: 'yearOfConstructionFrom',
            setOption: (option: any) => this.setOptionFilter(option, 'yearOfConstructionFrom'),
            options: this.getOptionsFilter('yearOfConstructionFrom')
          },
          {
            title: 'Тип кузова',
            fieldName: 'constructionType',
            setOption: (option: any) => this.setOptionFilter(option, 'constructionType'),
            options: this.getOptionsFilter('constructionType')
          },
        ]
      }
    }
  },
  watch: {
    filtersApplicability: {
      handler() {
        const filterKeys = Object.keys(this.filtersApplicability)

        const duplicateConfirmed = this.getParamApplicability.duplicateConfirmed
        const duplicateUnconfirmed = this.getParamApplicability.duplicateUnconfirmed

        let content = [] as any

        if (this.type === 'CONFIRMED') {
          content = JSON.parse(JSON.stringify(this.getParamApplicability.duplicateConfirmed))
        }

        if (this.type === 'UNCONFIRMED') {
          content = JSON.parse(JSON.stringify(this.getParamApplicability.duplicateUnconfirmed))
        }

        filterKeys.map((filterBy: string) => {
          if (this.filtersApplicability[filterBy]) {
            if (filterBy === 'kw' && this.type === 'CONFIRMED') {
              content = content.filter((vehicle: any) => {
                return `${vehicle.kw} kW / ${vehicle.hp} PS` === this.filtersApplicability.kw
              })
            } else if (filterBy === 'kw' && this.type === 'UNCONFIRMED') {
              content = content.filter((vehicle: any) => {
                return `${vehicle.powerKwFrom}kW / ${vehicle.powerHpFrom}hp` === this.filtersApplicability.kw
              })
            } else if (filterBy === 'yearOfConstructionFrom') {
              content = content.filter((vehicle: any) => {
                return this.creationDate(vehicle.yearOfConstructionFrom) === this.filtersApplicability.yearOfConstructionFrom
              })
            } else if (filterBy === 'show') {
              if (this.filtersApplicability[filterBy] === 'Только новые') {
                content = duplicateUnconfirmed.filter((e: any) => {
                  return !duplicateConfirmed.some((c: any) => c.tecdocVehicleId === e.carId)
                })
              }

              if (this.filtersApplicability[filterBy] === 'Есть в подтвержденных') {
                content = duplicateUnconfirmed.filter((unconfirmedVehicle: any) => {
                  const index = duplicateConfirmed.findIndex((confirmedVehicle: any) => {
                    return confirmedVehicle.tecdocVehicleId === unconfirmedVehicle.carId
                  })

                  return index >= 0
                      ? duplicateUnconfirmed
                      : null
                })

              }
            } else {
              content = content.filter((vehicle: any) => {
                return vehicle[filterBy] === this.filtersApplicability[filterBy]
              })
            }
          }

          if (this.type === 'CONFIRMED') {
            this.content.confirmed = content
          }
          if (this.type === 'UNCONFIRMED') {
            this.content.unconfirmed = content
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    if (this.type === 'UNCONFIRMED' && this.content.key === "APPLICABILITY") {
      this.filtersApplicability = {show: 'Только новые'}
    }
  },
  beforeUnmount() {
    if (this.type === 'CONFIRMED' && this.content.key === "APPLICABILITY") {
      this.filtersApplicability = {}
      this.content.confirmed = this.getParamApplicability.duplicateConfirmed
    }
  }
});
