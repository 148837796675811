
import { defineComponent, ref, watch, onMounted, computed, nextTick } from 'vue';
import {useRoute} from 'vue-router';
import articles from "@/services/articles";
import debounce from "@/components/debounce";
import brand from "@/services/brand";
import params from "@/services/params";
import { store } from '@/store';

export default defineComponent({
  name: 'SearchEntities',
  emits: ['update'],
  props: {
    text: {
      type: String,
      default: () => null
    },
    type: {
      type: String,
      default: () => null
    },
    // Для поиска артикулов (id товарной группы)
    productGroup: {
      type: Object,
      default: () => null
    },
    // Для поиска брендов (CM / OE)
    brand: {
      type: String,
      default: () => null
    }
  },
  setup(props, {emit}) {
    const article = computed(() => store.getters.getArticleCard) as any
    const route = useRoute();
    const searchText = ref('' as string);
    const original = ref([] as Array<any>);
    const content = ref([] as Array<any>);
    const rows = ref([] as Array<any>);

    function setRows() {
      let result = [] as any;
      if (props.type === 'ARTICLE' || props.type === 'ANALOG') result = ['Артикул', 'Товарная группа'];
      if (props.type === 'BRAND') result = ['Бренд'];
      rows.value = result
    }

    async function search(item: string) {
      if (item.length > 0) {
        try {
          let result = [];
          if (props.type === 'ARTICLE' || props.type === 'ANALOG') {
            if (props.productGroup) {
              result = await articles.findArticleByNameAndProductGroup(item, props.productGroup.id);
            } else {
              result = await articles.findArticleByName(item);
            }
            let temp = props.productGroup ?
              result.content.filter((o: any) => String(o.articleId) !== route.params.id) :
              result.map((o: any) => o.article).filter((o: any) => String(o.id) !== route.params.id);
            original.value = temp;
            content.value = temp.map((o: any) => {
              return [o.article, o.productGroup ? o.productGroup.desc : null]
            })
          }
          if (props.type === 'PARAMETER') {
            if (article.value.productGroup.id) { // разделяю логику, если у нас компонент по поиску в карточке артикула, тогда мы ищем параметры по конкретной товарной группе
              const parametersByProductGroup = await params.getAllByProductGroup(article.value.productGroup.id) as any
              result = parametersByProductGroup.filter((parameter: any) => {
                return parameter.name.toLowerCase().includes(item.toLowerCase().trim())
              })

              original.value = result;
              content.value = result.map((parameter: any) => {
                if (parameter.units) {
                  return [`${parameter.name} [${parameter.paramUnits || parameter.units}]`]
                } else {
                  return [`${parameter.name}`]
                }
              })
            } else {
              result = await params.searchParametersByName(item);
              original.value = result.content;
              content.value = result.content.map((o: any) => {
                if (o.paramUnits) {
                  return [`${o.name} [${o.paramUnits}]`]
                } else {
                  return [`${o.name}`]
                }
              })
            }
          }
          if (props.type === 'BRAND') {
            if (props.brand === 'analog') {
              const cmBrands = await brand.findByPrefix('cm', item)
              const oeBrands = await brand.findByPrefix('oe', item)

              result = {
                content: [
                  ...cmBrands.content,
                  ...oeBrands.content
                ]
              } as any
            } else {
              result = await brand.findByPrefix(props.brand, item)
            }

            original.value = result.content;
            content.value = result.content.map((o: any) => {
              return [o.brand]
            })
          }
        } catch (error) {
          throw error
        }
      } else {
        original.value = [];
        content.value = []
      }
    }

    function updateEntity(indexContent: number, indexValue: number) {
      if (!indexValue) emit('update', original.value[indexContent])
    }

    watch(() => props.text, (val) => {
      original.value = [];
      content.value = [];
      searchText.value = val;

      const input = document.getElementById('input')!;
      input.focus()
    });

    onMounted(() => {
      setRows()
    });

    return {searchText, content, rows, search, updateEntity, ...debounce()}
  }
})
