
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomTrigger',
  props: {
    isOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getTriggerClass(): any {
      return [
        'trigger',
        this.isOpen && 'trigger__icon-turned trigger__active',
      ]
    }
  },
})
