
import { defineComponent } from 'vue';
import BarcodesList from '@/components/article-card/barcodes/BarcodesList.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default defineComponent({
  name: 'Barcodes',
  props: {
    items: {
      type: Array,
      default: [],
    }
  },
  components: {
    BaseIcon,
    BarcodesList
  },
});
