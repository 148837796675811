
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import CustomSelectModal from '@/components/ui/CustomSelectModal.vue';
import vehicles from '@/services/vehicles';
import articles from '@/services/articles';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import vehicleModels from "@/services/vehicleModels";
import vehicleBrands from "@/services/vehicleBrands";
import crossApplicab from "@/services/crossApplicab";
import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import Trigger from "@/components/dropdown/Trigger.vue";
import DropdownOptions from "@/components/dropdown/DropdownOptions.vue";

export default defineComponent({
  name: 'AddVehicleModal',
  emits: ['closeModal'],
  components: {
    CustomSelectModal,
    ModalWrapper,
    BaseSelect,
    BaseDropdown,
    BaseIcon,
    Trigger,
    DropdownOptions
  },
  props: {
    vehicle: {
      type: Object,
      default: () => [] as any
    }
  },
  data: () => ({
    vehicleBrands: [] as any,
    filteredVehicleBrands: [] as any,
    vehicleModels: [] as any,
    vehicleModelsUnblocked: [] as any,
    vehicleModelsBlocked: [] as any,
    vehicles: [] as any,
    addedVehicles: [] as any,
    selectedBrand: null as any,
    selectedModel: null as any,
    selectedFuelType: null as any,
    selectedBodyType: null as any,
    selectedEnginePower: null as any,
    selectedEngineCode: null as any,
    selectedEngineValue: null as any,
    selectedVehicleModification: null as any,
    selectedDriveType: null as any,
    selectedYearProduction: null as any,
    tempVehicleForAdditing: null as any,
    addedYet: false as boolean,
    yearProductions: [] as any,
    vehicleData: {
      fuel: null as any,
      body: null as any,
      enginePower: null as any,
      engineValue: null as any,
      modification: null as any,
      drive: null as any,
      yearProduction: null as any,
      models: null as any,
    },
    tecdocId: null as any,
    modelForCreated: {
      modelConstruction: null as any,
      constructionYearFrom: null as number | null,
      constructionYearTo: null as number | null,
      category: 'PASSENGER' as any,
    } as any,
    modeCreateModel: false as boolean,
    modeCreateBrand: false as boolean,
    allFilteredVehicles: [] as any,
    dropdownValue: null as any,
    vehicleTypes: null as any,
  }),
  computed: {
    getModelConstructionYears(): any {
      return (str: string) => {
        str = this.modelForCreated['constructionYearFrom'] && this.modelForCreated['constructionYearFrom'] >= 1900  ? this.modelForCreated['constructionYearFrom'] + '-' : ''
        if (str.length && this.modelForCreated['constructionYearTo']) {
          str += String(this.modelForCreated['constructionYearTo'])
        }
        this.modelForCreated.modelConstruction = str
        return str
      }
    },
    getOptionsCategoryModel(): any {
      return this.vehicleTypes.map((type: any) => type.value)
    },
    modelExample() {
      return {
        modelConstruction: '200304',
        brandId: null,
        brand: null,
        supplier: {
          id: 1
        },
        constructionYearFrom: 'укажите год начала производства',
        constructionYearTo: 'укажите год окончания производства',
        category: 'PASSENGER',
      }
    },
    getFieldName() {
      return (key: string) => {
        switch (key) {
          case 'modelConstruction':
            return 'Годы производства'
            break;
          case 'constructionYearFrom':
            return 'Начало производства'
            break;
          case 'constructionYearTo':
            return 'Окончание производства'
            break;
          case 'category':
            return 'Категория'
            break;
          default:
            break;
        }
      }
    },
    canEdit() {
      return (key: string) => {
        switch (key) {
          case 'modelConstruction':
          case 'constructionYearFrom':
          case 'constructionYearTo':
          case 'category':
            return true
            break;
          default:
            return false
            break;
        }
      }
    },
    checkButtonDisabled(): any {
      if (this.vehicle && this.vehicle.length) {
        return !(this.selectedBrand && this.selectedModel && this.selectedYearProduction && this.filteredVehicles.length === 0);
      }

      if (this.modeCreateModel) {
        // не заполнены какие-то поля
        return !this.getModelConstructionYears().length || !(this.selectedBrand &&
            this.selectedModel &&
            this.selectedYearProduction &&
            this.selectedBodyType &&
            this.selectedDriveType &&
            this.selectedFuelType &&
            this.selectedEnginePower &&
            this.selectedEngineValue && this.filteredVehicles.length === 0);
      }

      return !(this.selectedBrand &&
          this.selectedModel &&
          this.selectedYearProduction &&
          this.selectedBodyType &&
          this.selectedDriveType &&
          this.selectedFuelType &&
          this.selectedEnginePower &&
          this.selectedEngineValue && this.filteredVehicles.length === 0);
    },
    filteredVehicles(): any {
      this.allFilteredVehicles = this.vehicles
      const filteredInfo: any = {
        ...(this.selectedYearProduction && {vehYears: this.selectedYearProduction}),
        ...(this.selectedEnginePower && {hp: this.selectedEnginePower}),
        ...(this.selectedEngineValue && {enginesL: this.selectedEngineValue}),
        ...(this.selectedVehicleModification && {modBodyNum: this.selectedVehicleModification}),
        ...(this.selectedBodyType && {bodyType: this.selectedBodyType}),
        ...(this.selectedFuelType && {fuelType: this.selectedFuelType}),
        ...(this.selectedDriveType && {driveType: this.selectedDriveType}),
     }

     Object.keys(filteredInfo).map((selected: any) => {
        this.allFilteredVehicles = this.allFilteredVehicles.filter((vehicle: any) => {
          if (vehicle.desc[selected] && filteredInfo[selected]) {
            return String(vehicle.desc[selected]).toLowerCase() === String(filteredInfo[selected]).toLowerCase()
          }
        })
     })

      if (this.allFilteredVehicles.length) {
        this.tempVehicleForAdditing = this.allFilteredVehicles[0]
      }

      return this.allFilteredVehicles
    },
    getVehicleInfo(): any {
      return (vehicle: any) => {
        const vehicleInfo = [vehicle.model.brand.brand, vehicle.model.model, vehicle.desc.vehYears, vehicle.desc.bodyType, vehicle.desc.driveType, vehicle.desc.modBodyNum, vehicle.desc.enginesL, vehicle.desc.fuelType, vehicle.desc.hp]
        return vehicleInfo.filter((info: any) => !!info)
      }
    },
    getAddedVehicleInfo(): any {
      return ({vehicle, otherInfoVehicle}: any) => {
        const vehicleInfo = [otherInfoVehicle.brand.brand, otherInfoVehicle.model.model ? otherInfoVehicle.model.model : this.selectedModel.modelFull, vehicle.desc.vehYears, vehicle.desc.bodyType, vehicle.desc.driveType, vehicle.desc.modBodyNum, vehicle.desc.fuelType, vehicle.desc.hp]
        return vehicleInfo.filter((info: any) => !!info)
      }
    },
    convertPickedVehicleInfo(): any {
      return (vehicle: any) => {
        console.log('Vehicle', vehicle)
        const convertedData: Array<any> = [
            vehicle.manufacturer, // Марка
            vehicle.model, // Модель авто
            vehicle && vehicle.carDesc && vehicle.carDesc.split(' ')[0], // V двигателя
            vehicle.cylinderCapacity, // V двигателя (ccm)
            vehicle.powerKwFrom && vehicle.powerHpFrom ? `${vehicle.powerKwFrom}kW / ${vehicle.powerHpFrom}hp` : null, // Мощность
            this.creationDate(vehicle.yearOfConstructionFrom), // Выпуск
            vehicle.constructionType, // Тип кузова
            vehicle && vehicle.carDesc && vehicle.carDesc.split(' ')[1], // Модификация
        ]

        return convertedData.join(' / ')
      }
    }
  },
  methods: {
    setOption(option: any) {
      const categoryOption = this.vehicleTypes.find((type: any) => type.value === option)
      if (categoryOption) {
        this.dropdownValue = option
        this.modelForCreated.category = categoryOption.key.toUpperCase()
        const refDropdown = this.$refs.menuItemDropdown as InstanceType<typeof BaseDropdown>;
        refDropdown.close()
      }
    },
    async setVehicleModels(brandId: any) {
      this.vehicleModels = await vehicleModels.getModelsByBrandIdAndBlocking(brandId, 'ALL');
    },
    creationDate(date: any) {
      if (String(date).length === 6) {
        return String(date)[4] + String(date)[5] + '.' + String(date)[0] + String(date)[1] + String(date)[2] + String(date)[3]
      }
    },
    removeAddedVehicle(index: number) {
      this.addedVehicles.splice(index, 1)
    },
    setDefaultSelectedState(exceptions?: any) {
      if (!exceptions?.find((exp: string) => exp === 'brand').length) {
        this.selectedBrand = null
        this.selectedModel = null
        this.vehicleModelsUnblocked = []
        this.vehicleModelsBlocked = []
        this.vehicleData.models = null

      }
      this.selectedVehicleModification = null
      this.selectedEngineValue = null
      this.selectedEnginePower = null
      this.selectedBodyType = null
      this.selectedDriveType = null
      this.selectedYearProduction = null
      this.selectedFuelType = null
      this.addedYet = false
      // this.vehicles = []
    },
    getModels() {
      let arr = this.vehicleModels.map((o: any) => {
        return o || null
      });

      this.vehicleData.models = [...new Set(arr)]
      return this.vehicleData.models
    },
    getYearProduction() {
      let arr = this.vehicles.map((o: any) => o.desc.vehYears ? o.desc.vehYears.toLowerCase() : null);
      this.vehicleData.yearProduction = [...new Set(arr)]

      return this.vehicleData.yearProduction
    },
    getFuelType(): any {
      let arr = this.vehicles.map((o: any) => o.desc.fuelType ? o.desc.fuelType.toLowerCase() : null);
      this.vehicleData.fuel = [...new Set(arr)]

      return this.vehicleData.fuel
    },
    getEnginePower(): any {
      let arr = this.vehicles.map((o: any) => o.desc.hp ? o.desc.hp.toLowerCase() : null);

      if (arr[0]) {
        arr.sort((a: string, b: string) => +a - +b);
      }

      this.vehicleData.enginePower = [...new Set(arr)]

      return this.vehicleData.enginePower
    },
    getBody(): any {
      let arr = this.vehicles.map((o: any) => o.desc.bodyType ? o.desc.bodyType.toLowerCase() : null);
      this.vehicleData.body = [...new Set(arr)]

      return this.vehicleData.body
    },
    getEngineValue(): any {
      let arr = this.vehicles.map((o: any) => o.desc.enginesL ? o.desc.enginesL.toLowerCase() : null);

      if (arr[0]) {
        arr.sort((a: string, b: string) => +a - +b);
      }

      this.vehicleData.engineValue = [...new Set(arr)]

      return this.vehicleData.engineValue
    },
    getVehicleModification(): any {
      let arr = this.vehicles.map((o: any) => o.desc.modBodyNum ? o.desc.modBodyNum.toLowerCase() : null);
      this.vehicleData.modification = [...new Set(arr)]

      return this.vehicleData.modification
    },
    getDrive(): any {
      const arr = this.vehicles.map((o: any) => o.desc.driveType ? o.desc.driveType.toLowerCase() : null);
      this.vehicleData.drive = [...new Set(arr)]

      return this.vehicleData.drive
    },
    async update(event: any, type: string) {
      if (type === 'VEHICLE_BRAND') {
        this.vehicleBrands = await vehicleBrands.getAllVehiclesBrands('ALL')
        this.vehicleBrands = this.vehicleBrands.filter((o: any) => o.brand.toLowerCase().includes(event.target.value.toLowerCase()))
      }

      if (type === 'VEHICLE_MODEL') {
        this.getModels()
        if (this.vehicle && this.vehicle.length) {
          this.vehicleData.models = this.vehicleModels.filter((o: any) => {
            if (o && o.tecdocId && this.vehicle.length) {
              return o.tecdocId === this.vehicle[0].modelId
            }
          })
        } else {
          this.vehicleData.models = this.vehicleModels.filter((o: any) => {
            if (o && o.model) {
              return o.model.toLowerCase().includes(event.target.value.toLowerCase())
            }
          })
        }
      }

      if (type === 'YEAR_OF_PRODUCTION') {
        this.getYearProduction()
        this.vehicleData.yearProduction = this.vehicleData.yearProduction.filter((o: any) => {
          if (o) {
            return o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }
      if (type === 'BODY_TYPE') {
        this.getBody()
        this.vehicleData.body = this.vehicleData.body.filter((o: any) => {
          if (o) {
            o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }

      if (type === 'VEHICLE_MODIFICATION') {
        this.getVehicleModification()
        this.vehicleData.modification = this.vehicleData.modification.filter((o: any) => {
          if (o) {
            return o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }

      if (type === 'ENGINE_VALUE') {
        this.getEngineValue()
        this.vehicleData.engineValue = this.vehicleData.engineValue.filter((o: any) => {
          if (o) {
            o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }

      if (type === 'FUEL_TYPE') {
        this.getFuelType()
        this.vehicleData.fuel = this.vehicleData.fuel.filter((o: any) => {
          if (o) {
            o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }

      if (type === 'ENGINE_POWER') {
        this.getEnginePower()
        this.vehicleData.enginePower = this.vehicleData.enginePower.filter((o: any) => {
          if (o) {
            o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }

      if (type === 'DRIVE_TYPE') {
        this.getDrive()
        this.vehicleData.drive = this.vehicleData.drive.filter((o: any) => {
          if (o) {
            o.toLowerCase().includes(event.target.value.toLowerCase())
          }
        })
      }
    },
    async pickBrand(item: string, value: any) {
      if (value.action === 'CREATE_BRAND') {
        this.modeCreateBrand = true
        const newBrand = {
          supplierId: 10,
          brand: value.value,
          visible: true,
          brandCustom: value.value,
          displayName: value.value
        }

        this.setDefaultSelectedState()
        this.vehicles = []
        this.allFilteredVehicles = []
        this.addedVehicles = []
        this.selectedBrand = value.value
        this.modeCreateBrand = true
        // const response = await vehicles.addNewBrand(payload)
        // console.log(response)
        return;
      }
      this.modeCreateBrand = false
      this.setDefaultSelectedState()
      this.vehicles = []
      this.allFilteredVehicles = []
      this.addedVehicles = []
      this.selectedBrand = value === 'CLEAR_SELECTED' ? null : value;

      // if (this.selectedBrand) {
      //   this.vehicleBrands = await vehicleBrands.getAllVehiclesBrands('ALL')
      //   this.vehicleBrands = this.vehicleBrands.filter((o: any) => o.brand.toLowerCase().includes(this.selectedBrand.brand.toLowerCase()))
      // }

      if (value !== 'CLEAR_SELECTED') {
        await this.setVehicleModels(value.id)
      }

      this.getModels()
    },
    async pickModel(item: string, value: any) {
      this.setDefaultSelectedState(['brand'])
      this.selectedModel = value === 'CLEAR_SELECTED' ? null : value

      if (value.action === 'CREATE_MODEL') {
        // const { id, supplier, } = this.vehicleBrands[0]
        this.modeCreateModel = true

        const result = {
          tecdocId: (this.vehicle && this.vehicle[0] && this.vehicle[0].modelId) || null,
          supplierId: 10,
          modelFull: `${value} ${this.selectedVehicleModification && `(${this.selectedVehicleModification})`}`,
          model: value,
          modelConstruction: '',
          modCstTermNo: 0,
          brandId: this.selectedBrand.id,
          modelSort: 0,
          constructionYearFrom: null,
          constructionYearTo: null,
          category: 'PASSENGER'
        }
        this.selectedModel = value.value
        this.modeCreateModel = true
        // this.selectedModel = await vehicleModels.addVehicleModel(result)
        // await this.setVehicleModels(result.brandId)
      } else {
        this.modeCreateModel = false
      }

      if (this.vehicle && this.vehicle.length) {
        this.getModels()

        if (this.vehicle && this.vehicle.length) {
          this.vehicleData.models = this.vehicleModels.filter((o: any) => {
            if (o && o.tecdocId && this.vehicle.length) {
              return o.tecdocId === this.vehicle[0].modelId
            }
          })
        }
      }

      if (value !== 'CLEAR_SELECTED') {
        if (this.selectedBrand?.id && this.selectedModel?.id) {
          this.vehicles = await vehicles.findVehicleByModelIds([this.selectedModel.id])
        }

        this.allFilteredVehicles = this.vehicles
        this.addedYet = this.vehicleModelsBlocked.some((el: any) => el.id === value.id)
        this.getDrive()
        this.getEngineValue()
        this.getBody()
        this.getVehicleModification()
        this.getFuelType()
        this.getEnginePower()
        this.getYearProduction()
      }
    },
    pickFuelType(item: string, value: any) {
      this.selectedFuelType = value === 'CLEAR_SELECTED' ? null : value
    },
    pickYearVehicle(item: string, value: any) {
      this.selectedYearProduction = value === 'CLEAR_SELECTED' ? null : value
    },
    pickBodyType(item: string, value: any) {
      this.selectedBodyType = value === 'CLEAR_SELECTED' ? null : value
    },
    pickEnginePower(item: string, value: any) {
      this.selectedEnginePower = value === 'CLEAR_SELECTED' ? null : value
    },
    pickEngineCode(item: string, value: any) {
      this.selectedEngineCode = value === 'CLEAR_SELECTED' ? null : value
    },
    pickEngineValue(item: string, value: any) {
      this.selectedEngineValue = value === 'CLEAR_SELECTED' ? null : value
    },
    pickVehicleModification(item: string, value: any) {
      this.selectedVehicleModification = value === 'CLEAR_SELECTED' ? null : value
    },
    pickDriveType(item: string, value: any) {
      this.selectedDriveType = value === 'CLEAR_SELECTED' ? null : value
    },
    async setUnblockModel() {
      await vehicleModels.setUnblockedModel(this.selectedModel.id).then(_ => {
        this.pickBrand('VEHICLE_BRAND', this.selectedBrand)
      })
    },
    async addNewVehicle() {
      const vehicle: any = {
        customDesc: null,
        tecdocId: this.vehicle && this.vehicle.length && this.vehicle[0].carId ?
          this.vehicle[0].carId :
          this.tecdocId !== null ?
            this.tecdocId : null,
        vehicleSort: 1,
        vehCstTermNo: 1,
        supplierId: this.vehicle && this.vehicle.length ? 1 : 10,
        modelId: this.selectedModel.id,
        vehicle: this.selectedBrand.brand || this.selectedBrand, //?
        vehicleFull: this.selectedBrand.brand || this.selectedBrand, //?
        ...(this.selectedEngineCode && { motor: this.selectedEngineCode }), // добавляем Код двигателя, если машина добавляется из применямости и у нее нет кода мотора
        desc: {
          bodyType: this.selectedBodyType || '',
          catalyticConverterType: '',
          cylinders: '',
          driveType: this.selectedDriveType || '',
          engineType: this.selectedFuelType || '',
          enginesL: this.selectedEngineValue || '',
          fuelType: this.selectedFuelType ? this.selectedFuelType : '',
          hp: this.selectedEnginePower || '',
          // id: example.desc.id, ???
          kw: Math.ceil(Number(this.selectedEnginePower) * 0.7355) || '',
          vehTerm: '',
          vehYears: this.selectedYearProduction || '',
          vehLinkingTargetType: 'PASSENGER',
          linkingTargetType: 'PASSENGER',
          lang: "RUS",
          modBodyNum: this.selectedVehicleModification || "",
          modBraBrandNo: 5,
          modConstructionYearFrom: null,
          modConstructionYearTo: null,
          modCstTerm: "",
          modCstTermNo: 0,
          modModelNo: 0,
          modSortNo: 0,
          valvesPerChamber: "0",
          vehCstTermNo: 0,
          vehModModelNo: 0,
          vehSortNo: 0,
        }
      }

      this.addedVehicles.push({
        vehicle,
        otherInfoVehicle: {
          brand: this.selectedBrand,
          model: this.selectedModel
        }
      })
    },
      async saveNewVehicles() {
      if (this.addedVehicles && this.addedVehicles.length) {
        let newVehicleResponse: any

        if (this.vehicle && this.vehicle.length) {
          // let findModelForTecdoc: any = []
          //
          // this.addedVehicles.map((vehicle: any) => {
          //   this.getModels()
          //   console.log(this.vehicleModels)
          //   const models = this.vehicleModels.filter((model: any) => {
          //     console.log(vehicle)
          //     console.log(model)
          //     if (model && model.tecdocId) {
          //
          //       // return model.tecdocId === vehicle.vehicle.modelId
          //     }
          //   })
          //
          //   findModelForTecdoc.push(...models)
          // })
          //
          // if (!findModelForTecdoc.length) {
          //   this.pickModel('VEHICLE_MODEL', {
          //     action: "CREATE_MODEL",
          //     value: this.selectedModel
          //   })
          // }
        }

        const {vehicle, vehicleFull, modelId} = this.addedVehicles[0].vehicle // получаем значения из добавленной машины

        if (this.modeCreateBrand) { // создаем бренд, если при добавлении в дропдауне засетался undefined, т.к. его не существует.
          const newBrandPayload = {
            supplierId: 10,
            brand: this.selectedBrand,
            visible: true,
            brandCustom: this.selectedBrand,
            displayName: this.selectedBrand
          }

          const responseNewBrand = await vehicleBrands.addNewBrand(newBrandPayload)
          this.selectedBrand = responseNewBrand
          this.addedVehicles[0].vehicle.vehicle = this.selectedBrand.brand
          this.addedVehicles[0].vehicle.vehicleFull = this.selectedBrand.brand
        }

        if (this.modeCreateModel) { // создаем модель, если при добавлении в дропдауне засетался undefined, т.к. его не существует.
          const newModelPayload = {
            tecdocId:  this.vehicle && this.vehicle.length ? this.vehicle[0].modelId : null, // ТИКТОД ДОЛЖЕН ПРИЛЕТАТЬ?
            supplierId: this.vehicle && this.vehicle.length ? 1 : 10,
            modelFull: `${this.selectedModel} ${this.selectedVehicleModification && `(${this.selectedVehicleModification})`}`,
            model: this.selectedModel,
            modelConstruction: this.modelForCreated.modelConstruction,
            modCstTermNo: 0,
            brandId: this.selectedBrand.id,
            modelSort: 0,
            constructionYearFrom: this.modelForCreated.constructionYearFrom,
            constructionYearTo: this.modelForCreated.constructionYearTo,
            category: this.modelForCreated.category
          }

          this.selectedModel = await vehicleModels.addVehicleModel(newModelPayload)
          this.addedVehicles[0].vehicle.modelId = this.selectedModel.id
          this.modeCreateModel = false
          await this.setVehicleModels(this.selectedBrand.id)
        }

        await Promise.all(this.addedVehicles.map(async (newVehicle: any) => {
          if (this.vehicle && this.vehicle.length && this.vehicle[0].carId) {
            newVehicle.vehicle.tecdocId = this.vehicle[0].carId
          } else if (this.tecdocId !== null) {
            newVehicle.vehicle.tecdocId = this.tecdocId
          }
          newVehicleResponse = await vehicles.addVehicle(newVehicle.vehicle)
        }))

        if (this.vehicle && this.vehicle.length) {
          await crossApplicab.postApplicabLocalVehicle(
            this.$store.getters.getArticleCard.articleId,
            [newVehicleResponse.id]
          ).then(async res => {
            let payload = {
              key: 'APPLICABILITY',
              value: await crossApplicab.getApplicability(String(this.$route.params.id))
            };
            this.$store.commit(CardArticleMutationTypes.CREATE_PARAMETER_VALUE, payload);
          })

          this.$store.commit(CardArticleMutationTypes.DELETE_UNCONFIRMED_VALUE, {
            key: 'APPLICABILITY',
            value: [this.tecdocId]
          });

          this.$emit('closeModal')
          setTimeout(() => {
            this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
          }, 100)
        }

        this.setDefaultSelectedState()
        this.vehicles = []
        this.allFilteredVehicles = []
        this.addedVehicles = []
        this.modeCreateBrand = false
        this.modeCreateModel = false
        this.tecdocId = null
      }
    }
  },
  watch: {
    async vehicle() {
      const {
        manufacturer,
        model,
        carId,
        modelId,
        powerHpTo,
        carDesc,
        manuId,
        yearOfConstructionFrom,
        constructionType,
        motorCodes
      } = this.vehicle[0] as any


      const сurrentPickedVehicleBrand = this.vehicleBrands.filter((brand: any) => {
        return brand.brand.trim().toLowerCase() === manufacturer.trim().toLowerCase()
      })

      this.pickBrand('VEHICLE_BRAND', {
        brand: manufacturer,
        id: сurrentPickedVehicleBrand[0].id
      })

      await this.setVehicleModels(сurrentPickedVehicleBrand[0].id)

      const currentModel = this.vehicleModels.filter((o: any) => {
        if (o && o.tecdocId && this.vehicle.length) {
          return o.tecdocId === this.vehicle[0].modelId
        }
      })

      if (currentModel[0] && currentModel[0].model) {
        this.pickModel('VEHICLE_MODEL', {
          model: currentModel[0] && currentModel[0].model,
          id: currentModel[0] && currentModel[0].id,
        });
      } else {
        this.pickModel('VEHICLE_MODEL', model);
        this.modeCreateModel = true
      }

      this.pickYearVehicle('YEAR_OF_PRODUCTION', this.creationDate(yearOfConstructionFrom))
      // this.pickFuelType('FUEL_TYPE')
      this.pickBodyType('BODY_TYPE', constructionType)
      // this.pickDriveType('DRIVE_TYPE')
      this.pickEnginePower('ENGINE_POWER', powerHpTo)
      // this.pickEngineValue('ENGINE_VALUE')
      this.pickVehicleModification('VEHICLE_MODIFICATION', carDesc)

      if (motorCodes) {
        this.pickEngineCode('ENGINE_CODE', motorCodes)
      }

      if (this.vehicle[0] && this.vehicle[0].carId) this.tecdocId = this.vehicle[0].carId
    }
  },
  async mounted() {
    this.vehicleBrands = await vehicleBrands.getAllVehiclesBrands('ALL')

    this.vehicleTypes = this.$store.getters.getVehicleCategories
    if (this.modelExample) {
      this.dropdownValue = this.vehicleTypes.find((type: any) => type.key === this.modelExample.category).value
    }
  },
});
