
import {
  defineComponent
} from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import { ConvertDate } from '@/components/converters/date';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import articleDocument from '@/services/articleDocument';

export default defineComponent({
  name: 'ArticleDocumentsTable',
  components: {
    DropdownEllipsis,
    BaseTable
  },
  data: () => ({
    allDocuments: null as any,
    formDataFile: null as any,
  }),
  methods: {
    async deleteDocument(documentId: any) {
      try {
        await articleDocument.deleteDocumentsByArticleIdAndId(this.$route.params.id, documentId)
        const deletedIndexDocument = this.allDocuments.findIndex((document: any) => document.id === documentId)
        this.allDocuments.splice(deletedIndexDocument, 1);
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    downloadFile(documentId: any) {
      const href = articleDocument.download(this.$route.params.id, documentId)
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
    async sendFile() {
      try {
        await articleDocument.addDocument(this.$route.params.id, this.formDataFile)
        this.formDataFile = null
        const input = document.querySelector('input[type=file]') as any
        input.value = '';
        this.allDocuments = await articleDocument.getDocumentsByArticleId(this.$route.params.id)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    parseTable(e: any) {
      let file = e.target.files;
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('desc', 'Описание');
      formData.append('articleId', String(this.$route.params.id));
      this.formDataFile = formData
    },
  },
  computed: {
    headers() {
      return [
        {
          title: 'Название файла',
          content: (item: any) => item.name
        },
        {
          title: 'Автор',
          content: (item: any) => item.editUser
        },
        {
          title: 'Дата',
          content: (item: any) => ConvertDate(item.editDate)
        }
      ]
    }
  },
  async mounted() {
    this.allDocuments = await articleDocument.getDocumentsByArticleId(this.$route.params.id)
  }
});
