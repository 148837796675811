
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import marketplaces from '@/services/marketplaces';
import AddMarketplaceModal from '@/components/modals/AddMarketplaceModal.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SelectionSlot from '@/components/modals/SelectionSlot.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'MarketplaceLinksView',
  components: {
    BaseIcon,
    BaseTable,
    BaseInput,
    AddMarketplaceModal,
    SelectionSlot,
    ModalWrapper
  },
  data: () => ({
    marketplaces: [] as any,
    marketplaceLinks: {} as any,
    linksArticle: [] as any,
    selectionMarketplaceId: null as any,
  }),
  methods: {
    async updateData() {
      await marketplaces.all().then(((allMarketplaces: any) => {
        this.marketplaces = allMarketplaces.filter((marketplace: any) => {
          return marketplace.name !== 'Horizontal'
        })
      }))
      await this.getLinks()
    },
    closeSelectionModal() {
      this.selectionMarketplaceId = null
      let element = document.getElementById(`modal-delete`)!;
      element.style.display = 'none'
    },
    closeAddMarketplaceModal() {
      let element = document.getElementById(`modal-add-marketplace`)!;
      element.style.display = 'none'
    },
    async actionSelectionModal() {
      await marketplaces.deleteMarketplace(this.selectionMarketplaceId)
      await this.updateData()
      this.closeSelectionModal()
    },
    openAddMarketplaceModal() {
      let element = document.getElementById(`modal-add-marketplace`)!;
      element.style.display = 'flex'
    },
    async updateLink(text: any, marketplace: any) {
      if (!text.length) {
        await marketplaces.deleteLink(this.marketplaceLinks[marketplace.name].linkId)
        delete this.marketplaceLinks[marketplace.name]
        await this.getLinks()
      } else {
        this.marketplaceLinks[marketplace.name] = {
          link: text,
          ...marketplace
        }
      }
    },
    async saveLink(marketplace: any) {
      const isLinkExist = this.linksArticle.findIndex((link: any) => {
        return marketplace.id === link.marketplaceId
      }) >= 0

      const marketplaceLink = this.marketplaceLinks[marketplace.name]

      const payload = {
        articleId: this.$route.params.id,
        marketplaceId: marketplaceLink.id,
        link: marketplaceLink.link
      }


      try {
        if (isLinkExist) {
          await marketplaces.updateLink(payload)
        } else {
          await marketplaces.saveLink(payload)
        }
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }


      await this.getLinks()
    },
    async getLinks() {
      this.linksArticle = await marketplaces.getLinksArticleId(this.$route.params.id)

      this.linksArticle.map(({ marketplace, link, linkId, marketplaceId }: any) => {
        this.marketplaceLinks[marketplace] = {
          link,
          name: marketplace,
          id: marketplaceId,
          linkId
        }
      })
    },
    async deleteMarketplace(marketplaceId: any) {
      this.selectionMarketplaceId = marketplaceId
      let element = document.getElementById(`modal-delete`)!;
      element.style.display = 'flex'
    }
  },
  computed: {
    headers() {
      return [
        {
          title: 'Маркетплейс',
          content: (item: any) => item.name
        },
        {
          title: 'Ссылка',
          content: (item: any) => ''
        },
      ]
    }
  },
  async mounted() {
    await this.updateData()
  }
});
