import { httpClient } from '@/httpClient';

function setLogistic(payload: any) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/params/logistic/logistic`, payload).then(response => response.data)
}

function getLogisticArticle(articleId: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/params/logistic/${articleId}`).then(response => response.data)
}

function getLogisticTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/params/logistic/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function uploadLogisticParams(file: FormData) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/params/logistic/export`, file)
}

export default {
  uploadLogisticParams,
  getLogisticTemplate,
  setLogistic,
  getLogisticArticle
}
