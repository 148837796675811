import { httpClient } from '@/httpClient';

function addVideo(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/videos`, payload);
}
function getVideosForArticle(articleName: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/videos/${articleName}`).then(response => response.data)
}

function deleteVideo(articleId: any, mediaName: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/videos?articleId=${articleId}&mediaName=${mediaName}`)
}

function getVideosByMarketplacesId(articleId: any, marketplaceId: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/videos/${articleId}?marketplaceId=${marketplaceId}`).then(response => response.data)
}

function addLinks(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/videos/link`, payload).then(response => response.data)
}

function deleteVideoLink(articleId: any, link: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/videos/link?articleId=${articleId}&link=${link}`)
}

export default {
  addVideo,
  getVideosForArticle,
  deleteVideo,
  getVideosByMarketplacesId,
  addLinks,
  deleteVideoLink
}
